import { StyleSheet } from 'react-native';
import { backgroundColor, bigFontSize, candidateColor1, candidateColor2, PAGE_WIDTH, textColor } from './StyleVariables';


export const GridActionStyle = StyleSheet.create({
   actions: {
      marginTop: PAGE_WIDTH / 20,
      flexDirection: "row",
      width: PAGE_WIDTH,
      height: PAGE_WIDTH / 3
   },
   toolbar: {
      flex: 2,
      flexDirection: "column",
      backgroundColor: backgroundColor,
      alignItems: "center",
      color: "#000",
   },
   button: {
      alignItems: "center",
      backgroundColor: "#DDDDDD",
      padding: 2,
      marginBottom: 5,
      marginTop: 5
   },
   text: {
      color: "#111111",
      fontSize: bigFontSize / 2,
   },
   enable: {},
   disable: {
      color: "#888"
   },
   btnGroup: {
      alignItems: "center",
      flexDirection: "row"
   },
   btn: {
      flex: 1
   },
   btnText: {
      padding: 5,
      flex: 1,
   },
   candidateColor1: {
      backgroundColor: candidateColor1,
   },
   candidateColor2: {
      backgroundColor: candidateColor2,
   },
   candidateColorNeutral: {
      backgroundColor: textColor,
      color: "black"
   }
});
