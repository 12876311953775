import React from 'react';
import { TouchableHighlight } from 'react-native';
import { Text, View } from '../components/Themed';
import { StyleSheet } from 'react-native';
import { backgroundColor, backgroundColorAlt, bigFontSize, borderAlt, selectedCellColor } from '../styles/StyleVariables';

export type NumberPadProps = {
  HandleCellClick: (number: number) => void,
  TakenValues: number[],
  Highlight: number | null
}

export default function NumberPad({ TakenValues, HandleCellClick, Highlight }: NumberPadProps): JSX.Element {

  function cellDisabled(number: number) {
    return TakenValues.some(v => v == number);
  }

  function getCellCss(index: number) {
    return [
      Style.numberBox,
      index % 2 == 1 ? Style.odd : {},
      index == Highlight ? Style.highlight : {}
    ];
  }

  function getCell(number: number) {
    const disabled = cellDisabled(number);
    return <TouchableHighlight
      key={number}
      disabled={disabled}
      style={getCellCss(number)}
      onPress={() => HandleCellClick(number)}>
      <Text style={[Style.text, disabled ? Style.disabled : {}]}>
        {number}
      </Text>
    </TouchableHighlight>;
  }

  function getRow(number: number) {
    return <View key={number} style={Style.row}>
      {[...Array(3).keys()].map(k => getCell((k + 1) + ((number) * 3)))}
    </View>
  }

  return <View style={Style.pad}>
    {[...Array(3).keys()].map((number) => getRow(number))}
  </View>
}

export const Style = StyleSheet.create({
  pad: {
    backgroundColor: backgroundColor,
    flex: 3,
  },
  row: {
    flexDirection: "row",
    flex: 1,
  },
  text: {
    fontSize: bigFontSize,
    alignSelf: "center"
  },
  disabled: {
    color: borderAlt
  },
  numberBox: {
    flex: 1,
    aspectRatio: 1,
    justifyContent: "center",
    borderWidth: 1,
    textAlign: "center",
    backgroundColor: backgroundColor,
    borderColor: borderAlt,
  },
  odd: {
    backgroundColor: backgroundColorAlt,
  },
  highlight: {
    backgroundColor: selectedCellColor,
  }
});
