import config from "../config";
import { getCellIndex } from "../functions/Grid";
import { Cell, CellPosition } from "../models/Cell";
import { PuzzleRequestCellData, getApiPuzzleRequestModel } from "./ApiModels";

export type HouseReferenceApiModel = { HouseType: HouseType, HouseIndex: number }

export type CellPositionApiModel = { Row: number, Column: number }

export type CandidateReferenceApiModel = { Cell: CellPosition, Candidate: number, Remove: boolean }

export type ArrowCandidate = { Cell: CellPosition, Candidate: number, Color: boolean };

export type HintArrowApiModel = { Start: ArrowCandidate, End: ArrowCandidate }

export type HintDetailsApiModel = { Message: string, Houses: HouseReferenceApiModel[], Cells: CellPositionApiModel[], Candidates: CandidateReferenceApiModel[], Arrows: HintArrowApiModel[] }

export enum HintType {
   "HintNotFound" = 2,
   "InvalidValueSelected" = 3,
   "CorrectValueBlocked" = 4,
   "UniqueAmongPeers" = 5,
   "SingleCandidate" = 6,
   "HiddenSingle" = 8,
   "CandidateLine" = 9,
   "DoublePair" = 10,
   "MultipleLines" = 11,
   "NakedPair" = 12,
   "BoxLineReduction" = 13,
   "HiddenPair" = 14,
   "NakedTriple" = 15,
   "HiddenTriple" = 16,
   "XWing" = 17,
   "YWing" = 18,
   "SimpleColoringRule2" = 19,
   "SimpleColoringRule4" = 20,
   "NakedQuad" = 21,
   "HiddenQuad" = 22
}

export type ApiHintChange = {
   Cells: CellPositionApiModel[],
   RemoveCandidates: number[] | null,
   SetValue: number | null,
}

export type ApiHint = {
   Type: HintType,
   Change: ApiHintChange,
   Details: HintDetailsApiModel[]
}

// TODO Change to union type
export enum HouseType {
   "Column" = 0,
   "Row" = 1,
   "Box" = 2
}

export enum TechniqueApi {
   "Iteration" = 0,
   "UniqueAmongPeers" = 1,
   "SingleCandidate" = 2,
   "HiddenSingle" = 4,
   "CandidateLine" = 5,
   "DoublePair" = 6,
   "MultipleLines" = 7,
   "NakedPair" = 8,
   "BoxLineReduction" = 9,
   "HiddenPair" = 10,
   "NakedTriple" = 11,
   "HiddenTriple" = 12,
   "XWing" = 13,
   "YWing" = 14,
   "SimpleColoringRule2" = 15,
   "SimpleColoringRule4" = 16,
   "NakedQuad" = 17,
   "HiddenQuad" = 18
}

export type Technique =
   | "Iteration"
   | "UniqueAmongPeers"
   | "HiddenSingle"
   | "SingleCandidate"
   | "CandidateLine"
   | "DoublePair"
   | "MultipleLines"
   | "NakedPair"
   | "BoxLineReduction"
   | "HiddenPair"
   | "NakedTriple"
   | "HiddenTriple"
   | "XWing"
   | "YWing"
   | "SimpleColoringRule2"
   | "SimpleColoringRule4"
   | "NakedQuad"
   | "HiddenQuad"
   | "Unknown"

export type Hint =
   CandidateMissing |
   HintNotFound |
   InvalidValueSelected |
   CorrectValueBlocked |
   UniqueAmongPeers |
   HiddenSingle |
   SingleCandidate |
   CandidateLine |
   DoublePair |
   MultipleLines |
   NakedPair |
   BoxLineReduction |
   HiddenPair |
   NakedTriple |
   HiddenTriple |
   XWing |
   YWing |
   SimpleColoringRule2 |
   SimpleColoringRule4 |
   NakedQuad |
   HiddenQuad;

export type HintNotFound = HintDetails & {
   Kind: "HintNotFound"
}
export type CandidateMissing = HintDetails & {
   Kind: "CandidateMissing",
   Candidate: number,
   CellIndex: number
}
export type InvalidValueSelected = HintDetails & {
   Kind: "InvalidValueSelected",
   CellIndex: number
}
export type CorrectValueBlocked = HintDetails & UnblockCandidateObject & {
   Kind: "CorrectValueBlocked"
}
export type HiddenSingle = SetValueObject & HintDetails & {
   Kind: "HiddenSingle"
}
export type SingleCandidate = SetValueObject & HintDetails & {
   Kind: "SingleCandidate"
}
export type UniqueAmongPeers = RemoveCandidatesObject & HintDetails & {
   Kind: "UniqueAmongPeers"
}
export type CandidateLine = RemoveCandidatesObject & HintDetails & {
   Kind: "CandidateLine"
}
export type DoublePair = RemoveCandidatesObject & HintDetails & {
   Kind: "DoublePair"
}
export type MultipleLines = RemoveCandidatesObject & HintDetails & {
   Kind: "MultipleLines"
}
export type NakedPair = RemoveCandidatesObject & HintDetails & {
   Kind: "NakedPair"
}
export type BoxLineReduction = RemoveCandidatesObject & HintDetails & {
   Kind: "BoxLineReduction";
}
export type HiddenPair = RemoveCandidatesObject & HintDetails & {
   Kind: "HiddenPair"
}
export type NakedTriple = RemoveCandidatesObject & HintDetails & {
   Kind: "NakedTriple"
}
export type HiddenTriple = RemoveCandidatesObject & HintDetails & {
   Kind: "HiddenTriple"
}
export type XWing = RemoveCandidatesObject & HintDetails & {
   Kind: "XWing"
}
export type YWing = RemoveCandidatesObject & HintDetails & {
   Kind: "YWing"
}
export type SimpleColoringRule2 = RemoveCandidatesObject & HintDetails & {
   Kind: "SimpleColoringRule2"
}
export type SimpleColoringRule4 = RemoveCandidatesObject & HintDetails & {
   Kind: "SimpleColoringRule4"
}
export type NakedQuad = RemoveCandidatesObject & HintDetails & {
   Kind: "NakedQuad"
}
export type HiddenQuad = RemoveCandidatesObject & HintDetails & {
   Kind: "HiddenQuad"
}

export type SetValueChange = {
   ChangeType: "SetValue";
   Cells: CellPosition[];
   Value: number;
}

export type RemoveCandidatesChange = {
   ChangeType: "RemoveCandidates";
   Cells: CellPosition[];
   RemoveCandidates: number[];
}

export type UnblockCandidateChange = {
   ChangeType: "UnblockCandidate";
   Cells: CellPosition[];
   Candidate: number;
}

export type SetValueObject = {
   Change: SetValueChange
}

export type RemoveCandidatesObject = {
   Change: RemoveCandidatesChange
}

export type UnblockCandidateObject = {
   Change: UnblockCandidateChange
}

export type HintDetails = {
   Details: HintDetailsApiModel[]
}

export type HintRequestModel = {
   PuzzleSolution: string,
   Puzzle: PuzzleRequestCellData[]
}

export async function fetchHint(puzzleSolution: string, puzzle: Cell[]): Promise<Hint | null> {
   var requestModel = getPuzzleRequestModel(puzzleSolution, puzzle);
   const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestModel)
   };

   try {
      const response = await fetch(`${config.API_URL}/hint`, requestOptions);
      const json = await response.json();
      if (json == null)
         return null;
      else
         return convertHint(json);
   } catch (error) {
      console.error(error);
      return Promise.reject(error);
   }
}

function getPuzzleRequestModel(puzzleSolution: string, cells: Cell[]): HintRequestModel {
   return {
      PuzzleSolution: puzzleSolution,
      Puzzle: cells.map(getApiPuzzleRequestModel)
   }
}

function getSetValue(hint: ApiHint): SetValueChange | null {
   if (hint.Change.SetValue == null)
      return null;

   return {
      ChangeType: "SetValue",
      Cells: hint.Change.Cells,
      Value: hint.Change.SetValue,
   };
}

function getRemoveCandidates(hint: ApiHint): RemoveCandidatesChange | null {
   if (hint.Change.RemoveCandidates == null)
      return null;

   return {
      ChangeType: "RemoveCandidates",
      Cells: hint.Change.Cells,
      RemoveCandidates: hint.Change.RemoveCandidates,
   };
}

function convertHint(hint: ApiHint): Hint | null {
   var removeCandidates = getRemoveCandidates(hint);
   var setValue = getSetValue(hint);

   switch (hint.Type) {
      case HintType.HintNotFound: return null;
      case HintType.CorrectValueBlocked: throw "CorrectValueBlocked: Not implemented in api";
      case HintType.InvalidValueSelected: return { Kind: "InvalidValueSelected", CellIndex: getFirstCellIndex(hint), Details: hint.Details };

      case HintType.SingleCandidate: return { ...{ Kind: "SingleCandidate" }, ...getSetHint(hint, setValue) };
      case HintType.HiddenSingle: return { ...{ Kind: "HiddenSingle" }, ...getSetHint(hint, setValue) };

      case HintType.UniqueAmongPeers: return { ...{ Kind: "UniqueAmongPeers" }, ...getRemoveHint(hint, removeCandidates) };
      case HintType.CandidateLine: return { ...{ Kind: "CandidateLine" }, ...getRemoveHint(hint, removeCandidates) };
      case HintType.DoublePair: return { ...{ Kind: "DoublePair" }, ...getRemoveHint(hint, removeCandidates) };
      case HintType.MultipleLines: return { ...{ Kind: "MultipleLines" }, ...getRemoveHint(hint, removeCandidates) };
      case HintType.NakedPair: return { ...{ Kind: "NakedPair" }, ...getRemoveHint(hint, removeCandidates) };
      case HintType.BoxLineReduction: return { ...{ Kind: "BoxLineReduction" }, ...getRemoveHint(hint, removeCandidates) };
      case HintType.HiddenPair: return { ...{ Kind: "HiddenPair" }, ...getRemoveHint(hint, removeCandidates) };
      case HintType.NakedTriple: return { ...{ Kind: "NakedTriple" }, ...getRemoveHint(hint, removeCandidates) };
      case HintType.HiddenTriple: return { ...{ Kind: "HiddenTriple" }, ...getRemoveHint(hint, removeCandidates) };
      case HintType.XWing: return { ...{ Kind: "XWing" }, ...getRemoveHint(hint, removeCandidates) };
      case HintType.YWing: return { ...{ Kind: "YWing" }, ...getRemoveHint(hint, removeCandidates) };
      case HintType.SimpleColoringRule2: return { ...{ Kind: "SimpleColoringRule2" }, ...getRemoveHint(hint, removeCandidates) };
      case HintType.SimpleColoringRule4: return { ...{ Kind: "SimpleColoringRule4" }, ...getRemoveHint(hint, removeCandidates) };
      case HintType.NakedQuad: return { ...{ Kind: "NakedQuad" }, ...getRemoveHint(hint, removeCandidates) };
      case HintType.HiddenQuad: return { ...{ Kind: "HiddenQuad" }, ...getRemoveHint(hint, removeCandidates) };
   }
}

function getFirstCellIndex(hint: ApiHint): number {
   var cell = hint.Change.Cells.pop();
   if (!cell)
      throw "Cannot parse hint";

   return getCellIndex(cell);
}

function getRemoveHint(hint: ApiHint, removeCandidates: RemoveCandidatesChange | null): RemoveCandidatesObject & HintDetails {
   if (removeCandidates == null)
      throw 'Cannot parse hint';

   return { Details: hint.Details, Change: removeCandidates };
}

function getSetHint(hint: ApiHint, setValue: SetValueChange | null): SetValueObject & HintDetails {
   if (setValue == null)
      throw 'Cannot parse hint';

   return { Details: hint.Details, Change: setValue };
}
