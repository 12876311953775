/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { RootStackParamList } from '../types';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      Main: 'main',
      Lobby: 'lobby',
      Play: 'play',
      Settings: 'settings',
      Statistics: 'stats',
      Tutorial: 'tutorial',
      Tutorial1: 'tutorial1',
      Test: 'test',
    },
  }
};

export default linking;
