import React, { ReactNode, useEffect, useState } from 'react';
import { PanResponder, View } from 'react-native';
import { Cell, CellPosition } from '../models/Cell';
import { GridStyle } from '../styles/GridStyle';
import {
   getCellIndex,
   getNewSelectionTrail,
   rowIndexes,
} from '../functions/Grid';
import { GridRowStyle } from '../styles/GridRowStyle';
import GridCell from './GridCell';
import { PAGE_WIDTH } from '../styles/StyleVariables';
import { useThrottle } from '@react-cmpt/use-throttle';
import { Hint } from '../api/Hints';

export type GridProps = {
   puzzle: Cell[],
   hint: Hint | null,
   handleSelectionChange: (trail: number[] | null) => void,
   handleCellClick: (cell: Cell) => void
}

export default function Grid({ puzzle, handleSelectionChange, handleCellClick, hint }: GridProps): JSX.Element {
   const [selection, setSelection] = useState((): number[] | null => null);
   var [throttleSelection, _] = useThrottle(selection, 75);

   useEffect(() => {
      handleSelectionChange(throttleSelection);
   }, [throttleSelection]);

   const panResponder = PanResponder.create({
      onMoveShouldSetPanResponderCapture: () => true,
      onPanResponderMove: (event) => {
         const cellWidth = PAGE_WIDTH / 9
         if (cellWidth == null) {
            return;
         }

         const locationX = event.nativeEvent.locationX;
         const locationY = event.nativeEvent.locationY;
         const selectionIndexes = puzzle.filter(c => c.Highlight?.Type == "Selected").map(c => c.Index) as number[];

         var trail = getNewSelectionTrail(selectionIndexes, puzzle, cellWidth, locationX, locationY);
         if (trail != null) {
            setSelection(trail);
         }
      }
   });

   function getCell(cellIndex: number): ReactNode {
      return <GridCell key={cellIndex} cell={puzzle[cellIndex]} handleCellClick={handleCellClick} />
   }

   function getRow(row: number): ReactNode {
      return <View key={row} style={[GridRowStyle.row]}>
         {rowIndexes[row].map(getCell)}
      </View>
   }

   function getRows(): ReactNode[] {
      return Array.from(Array(9).keys()).map(getRow);
   }

   // https://github.com/krismuniz/proto-arrows?ref=reactjsexample.com
   // https://www.productboard.com/blog/how-we-implemented-svg-arrows-in-react-the-curvature-2-3/
   function getCandidateCoordinates(candidate: number): [number, number] {
      // From top left i should step 4 pixels always.
      // Or find row width and then remove cell width.
      // This is complicated...

      var contentWidth = (PAGE_WIDTH / 9) - 8;
      var x = 5 + Math.floor((candidate - 1) % 3) * (contentWidth / 3);
      var y = 5 + Math.floor((candidate - 1) / 3) * (contentWidth / 3);
      return [x, y];
   }

   function getArrow(i: number, cell1: CellPosition, candidate1: number, cell2: CellPosition, candidate2: number) {
      var c1 = puzzle[getCellIndex(cell1)];
      var c2 = puzzle[getCellIndex(cell2)];
      var cellWidth = (PAGE_WIDTH / 9);

      var [cand1X, cand1Y] = getCandidateCoordinates(candidate1);
      var [cand2X, cand2Y] = getCandidateCoordinates(candidate2);

      // Aim for top left corner of candidate
      var start1X = Math.round(cellWidth * (c1.Column - 1)) + cand1X;
      var start1Y = Math.round(cellWidth * (c1.Row - 1)) + cand1Y;
      var start2X = Math.round(cellWidth * (c2.Column - 1)) + cand2X;
      var start2Y = Math.round(cellWidth * (c2.Row - 1)) + cand2Y;

      var candidateWidth = (cellWidth - 8) / 3;
      if (start1X < start2X) {
         start1X += candidateWidth;
      }
      else if (start2X < start1X) {
         start2X += candidateWidth;
      }
      else if (start1X == start2X) {
         start2X += candidateWidth / 2;
         start1X += candidateWidth / 2;
      }

      if (start1Y < start2Y) {
         start1Y += candidateWidth;
      }
      else if (start2Y < start1Y) {
         start2Y += candidateWidth;
      }
      else if (start1Y == start2Y) {
         start2Y += candidateWidth / 2;
         start1Y += candidateWidth / 2;
      }

      return <line key={i} x1={start1X} y1={start1Y} x2={start2X} y2={start2Y} markerEnd="url(#arrow)" />;
   }

   function getArrows() {
      return hint?.Details.flatMap((h, i) => {
         return h.Arrows.map((a, j) => {
            return getArrow((i + 1) * (j + 1), a.Start.Cell, a.Start.Candidate, a.End.Cell, a.End.Candidate);
         });
      });
   }

   return <View>
      <View style={GridStyle.grid} {...panResponder.panHandlers}>
         {getRows()}
      </View>
      {
         <svg pointerEvents={"none"} style={GridStyle.svg} stroke="red" fill="#FFF" >
            <defs>
               <marker
                  id="arrow"
                  viewBox="0 0 10 10"
                  refX="10"
                  refY="5"
                  markerWidth="5"
                  markerHeight="5"
                  orient="auto-start-reverse">
                  <path d="M 0 0 L 10 5 L 0 10 z" />
               </marker>
            </defs>
            {getArrows()}
         </svg>
      }
   </View >
}
