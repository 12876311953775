import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Hint } from '../api/Hints';
import { Text, View } from '../components/Themed';
import { StyleSheet } from 'react-native';
import { PAGE_WIDTH, bigFontSize } from '../styles/StyleVariables';

export type HintPanelProps = {
   Hint: Hint,
   HandleApply: (hint: Hint) => void,
   HandleClose: () => void
}

export default function HintPanel({
   Hint,
   HandleApply,
   HandleClose,
}: HintPanelProps): JSX.Element {
   return <View style={Styles.hints}>
      <div style={Styles.hint}>
         <div>{Hint.Kind}: </div>
         {Hint.Details.map((d, i) => <div key={i} style={Styles.hintDetails}>{d.Message}</div>)}
         <div style={Styles.hintResult}>
            <TouchableOpacity style={Styles.button} onPress={() => HandleApply(Hint)}>
               <Text style={Styles.text}>Apply</Text>
            </TouchableOpacity>
            <TouchableOpacity style={Styles.button} onPress={HandleClose}>
               <Text style={Styles.text}>Close</Text>
            </TouchableOpacity>
         </div>
      </div>
   </View >
}

const Styles = StyleSheet.create({
   hints: {
      width: PAGE_WIDTH,
   },
   hintLink: {
      color: "blue",
      textDecorationLine: "underline"
   },
   hint: {
      marginTop: 5,
   },
   hintDetails: {
      color: "#FFFFFF"
   },
   hintResult: {
      display: 'flex',
      flexDirection: 'row'
   },
   hintResultText: {
      color: "#FFFFFF"
   },
   hintAction: {
   },
   button: {
      alignItems: "center",
      backgroundColor: "#DDDDDD",
      padding: 2,
      marginLeft: 5
   },
   text: {
      color: "#111111",
      fontSize: bigFontSize / 3
   },
});
