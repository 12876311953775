import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Text, View } from '../components/Themed';
import { PadAction } from '../models/PadAction';
import { PencilColor } from '../models/PencilColor';
import { PencilType } from '../models/PencilType';
import { GridActionStyle } from '../styles/GridActionStyle';
import NumberPad from './NumberPad';

export type GridActionsProps = {
   HandleNumberClick: (number: number) => void,
   HandleAction: (action: PadAction) => void,
   Pen: PencilType,
   AutoClear: boolean,
   TakenValues: number[],
   BlockCandidate: boolean,
   SelectedPencilColor: PencilColor,
   Highlight: number | null,
   SetPencilColor: (color: PencilColor) => void
}

export default function GridActions({
   HandleNumberClick: HandlePadCellClick,
   HandleAction,
   AutoClear,
   Pen,
   BlockCandidate,
   TakenValues,
   SelectedPencilColor,
   SetPencilColor,
   Highlight
}: GridActionsProps): JSX.Element {

   return <View style={GridActionStyle.actions}>
      <View style={GridActionStyle.toolbar}>
         <TouchableOpacity style={GridActionStyle.button} onPress={() => HandleAction("TogglePen")}>
            <Text style={GridActionStyle.text}>{Pen}</Text>
         </TouchableOpacity>
         <TouchableOpacity style={GridActionStyle.button} onPress={() => HandleAction("Erase")}>
            <Text style={GridActionStyle.text}>Erase</Text>
         </TouchableOpacity>
         <TouchableOpacity style={GridActionStyle.button} onPress={() => HandleAction("ToggleBlockCandidate")}>
            <Text style={GridActionStyle.text}>Block: {BlockCandidate ? "ON" : "OFF"}</Text>
         </TouchableOpacity>
         <TouchableOpacity style={GridActionStyle.button} onPress={() => HandleAction("ClearSelection")}>
            <Text style={GridActionStyle.text}>Unselect</Text>
         </TouchableOpacity>
         <View style={GridActionStyle.btnGroup}>
            <TouchableOpacity style={[GridActionStyle.btn]} onPress={() => SetPencilColor("Neutral")}>
               <Text style={[GridActionStyle.btnText, SelectedPencilColor === "Neutral" ? GridActionStyle.candidateColorNeutral : null]}>n</Text>
            </TouchableOpacity>
            <TouchableOpacity style={[GridActionStyle.btn]} onPress={() => SetPencilColor("Color1")}>
               <Text style={[GridActionStyle.btnText, SelectedPencilColor === "Color1" ? GridActionStyle.candidateColor1 : null]}>1</Text>
            </TouchableOpacity>
            <TouchableOpacity style={[GridActionStyle.btn]} onPress={() => SetPencilColor("Color2")}>
               <Text style={[GridActionStyle.btnText, SelectedPencilColor === "Color2" ? GridActionStyle.candidateColor2 : null]}>2</Text>
            </TouchableOpacity>
         </View>
      </View>
      <NumberPad Highlight={Highlight} TakenValues={TakenValues} HandleCellClick={HandlePadCellClick} />
      <View style={GridActionStyle.toolbar}>
         <TouchableOpacity style={GridActionStyle.button} onPress={() => HandleAction("FillAllCandidates")}>
            <Text style={GridActionStyle.text}>Fill</Text>
         </TouchableOpacity>
         <TouchableOpacity style={GridActionStyle.button} onPress={() => HandleAction("ClearCandidates")}>
            <Text style={GridActionStyle.text}>Clear</Text>
         </TouchableOpacity>
         <TouchableOpacity style={GridActionStyle.button} onPress={() => HandleAction("ToggleAutoClear")}>
            <Text style={GridActionStyle.text}>Auto clear: {AutoClear ? "ON" : "OFF"}</Text>
         </TouchableOpacity>
         <TouchableOpacity style={GridActionStyle.button} onPress={() => HandleAction("Undo")}>
            <Text style={GridActionStyle.text}>Undo</Text>
         </TouchableOpacity>
         <TouchableOpacity style={GridActionStyle.button} onPress={() => HandleAction("Hint")}>
            <Text style={GridActionStyle.text}>Hint</Text>
         </TouchableOpacity>
         <TouchableOpacity style={GridActionStyle.button} onPress={() => HandleAction("Solve")}>
            <Text style={GridActionStyle.text}>Solve</Text>
         </TouchableOpacity>
      </View>
   </View >
}
