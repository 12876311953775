import React, { useEffect, useState } from 'react';
import { AvailableCandidate, BlockedCandidate, CandidatesCell, Cell } from '../models/Cell';
import { PencilColor } from '../models/PencilColor';
import { View } from './Themed';
import { PencilType } from '../models/PencilType';
import GridActions from './GridActions';
import { PadAction } from '../models/PadAction';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RootStackParamList } from '../types';
import {
   CandidateMissing,
   Hint,
   InvalidValueSelected,
   RemoveCandidatesChange,
   SetValueChange,
   UnblockCandidateChange
} from '../api/Hints';
import {
   createPuzzleFromSignature,
   getClickPenChanges,
   getHousesValues,
   getCellSelectionTakenValues,
   fillAllCandidatesInCell,
   updateCandidateCollisions,
   removeHighlightAndGhosts,
   addGhostsAndHintMarks,
   getLastCandidates,
   getBlockedCandidates,
   highlightCandidates,
   removeSelection,
   maybeGetAutoClearChanges,
   removeTakenHighlightCell
} from '../functions/Grid';
import useDebounce from '../hooks/useDebounce';
import CompletionOverlay from './CompletionOverlay';
import { fetchSolution, Solution } from '../api/Solutions';
import HintPanel from './HintPanel';
import Grid from './Grid';
import { getHint } from '../functions/Hint';

export type PlayPanelProps = {
   puzzleId: number,
   puzzleSignature: string,
   puzzleSolution: string,
   navigation: NativeStackNavigationProp<RootStackParamList, "Play", undefined>
}

export default function PlayPanel({ puzzleSignature, navigation, puzzleSolution }: PlayPanelProps): JSX.Element {
   const [puzzle, setPuzzle] = useState(createPuzzleFromSignature(puzzleSignature));
   const [pen, setPen] = useState((): PencilType => "Pencil");
   const [autoClear, handleSetAutoClear] = useState(true);
   const [blockCandidate, handleSetBlockCandidate] = useState(false);
   const [changeTrail, setChangeTrail] = useState((): Cell[][] => []);
   const [pencilColor, setPencilColor] = useState((): PencilColor => "Neutral");
   const [hint, setHint] = useState((): Hint | null => null);
   const debouncedPuzzle = useDebounce<Cell[]>(puzzle, 110);
   const [finished, setFinished] = useState(false);
   const [taken, setHighlightTaken] = useState((): number | null => null);

   useEffect(clearPuzzle, [puzzleSignature]);

   function clearPuzzle() {
      setPuzzle(createPuzzleFromSignature(puzzleSignature));
      setHint(null);
      setChangeTrail([]);
   }

   useEffect(() => {
      if (!puzzle.some(cell => cell.Type == "Candidates")) { setFinished(true); }
   }, [debouncedPuzzle]);

   useEffect(() => {
      if (hint == null) {
         var updatedPuzzle = puzzle.map(removeHighlightAndGhosts);
         setPuzzle(updatedPuzzle);
      }
      else {
         var updatedPuzzle = puzzle.map(removeHighlightAndGhosts);
         updatedPuzzle = addGhostsAndHintMarks(hint, updatedPuzzle, puzzleSolution);
         setPuzzle(updatedPuzzle);
      }
   }, [hint]);

   useEffect(() => {
      if (taken) {
         var updatedPuzzle = puzzle.map(c => {
            var updatedCell = getTakenCell(c, taken);
            return highlightCandidates(updatedCell, taken);
         });
         storeStateAndApplyChange(updatedPuzzle, false);
      }
      else {
         var updatedPuzzle = puzzle.map(c => removeTakenHighlightCell(c));
         storeStateAndApplyChange(updatedPuzzle, false);
      }
   }, [taken]);

   function getTakenCell(cell: Cell, taken: number): Cell {
      return cellIsTaken(cell, taken) ? { ...cell, Highlight: { Type: "Taken" } } : { ...cell, Highlight: null }
   }

   function cellIsTaken(cell: Cell, taken: number): boolean {
      var blockedCandidates = getBlockedCandidates(cell);
      var takenByPeers = getHousesValues(cell, puzzle).concat(blockedCandidates);
      return takenByPeers.some(t => t == taken || (cell.Type == "Value" && t == cell.SelectedValue))
         || cell.Type == "Value"
         || cell.Type == "Given";
   }

   function handleCellClick(cell: Cell) {
      var updatedPuzzle: Cell[] = puzzle.map(c => c.Index == cell.Index ? { ...puzzle[cell.Index], Highlight: { Type: "Selected" } } : removeSelection(c));
      setPuzzle(updatedPuzzle);

      if (pen == "InkTemporaryPencil") {
         setPen("Ink");
      }
   }

   function handleClearCollisions() {
      var updatedPuzzle = puzzle.map(c => c.Type == "Candidates" ? { ...c, Candidates: c.Candidates.filter(candidate => candidate.Type == "Blocked" || candidate.Type == "Available" && !candidate.Collision) } : c);
      storeStateAndApplyChange(updatedPuzzle, false);
   }

   function handleNumberPadClick(number: number): void {
      var selectedCells = puzzle.filter(c => c.Highlight?.Type == "Selected");
      if (selectedCells.length > 0) {
         var newPuzzle = getClickPenChanges(puzzle, pen, pencilColor, blockCandidate, number);
         storeStateAndApplyChange(newPuzzle, true);
      }
      else {
         setHighlightTaken(number);
      }
   }

   function handleTogglePen(): void {
      switch (pen) {
         case "Pencil": return setPen("Ink");
         default: return setPen("Pencil");
      }
   }

   function storeStateAndApplyChange(updatedPuzzle: Cell[], updateCollisions: boolean) {
      if (updateCollisions) {
         updatedPuzzle = updateCandidateCollisions(updatedPuzzle);
      }

      if (autoClear) {
         updatedPuzzle = maybeGetAutoClearChanges(updatedPuzzle);
      }

      setChangeTrail(changeTrail.concat([puzzle]));
      setPuzzle(updatedPuzzle);
   }

   function handleEraseSelection() {
      var updatedPuzzle: Cell[] = puzzle.map(c => {
         switch (c.Type) {
            case "Value": return { ...c, Type: "Candidates", Candidates: [], Highlight: null };
            case "Candidates": return { ...c, Candidates: [], Highlight: null };
            case "Given": return c;
         };
      });
      storeStateAndApplyChange(updatedPuzzle, true);
   }

   function handleUndo() {
      const previousPuzzle = changeTrail.pop();
      if (!previousPuzzle) { return; }

      var cleanPuzzle = previousPuzzle.map(removeHighlightAndGhosts);

      setChangeTrail(changeTrail.concat());
      setPuzzle(cleanPuzzle);
   }

   function handleFillAllCandidates() {
      const newPuzzle = puzzle.map(c => fillAllCandidatesInCell(c, puzzle));
      storeStateAndApplyChange(newPuzzle, true);
   }

   function handleClearSelection() {
      var updatedPuzzle = puzzle.map(c => { return { ...c, Highlight: null } });
      setHighlightTaken(null);
      storeStateAndApplyChange(updatedPuzzle, false);
   }

   function getSelectionTakenValues(): number[] {
      if (pen == "Pencil" || pen == "InkTemporaryPencil") { return []; }
      return getCellSelectionTakenValues(puzzle);
   }

   async function handleSolve() {
      const solution = await fetchSolution(puzzle);
      switch (solution?.Kind) {
         case null: return;
         case "NoSolution": return;
         case "Solution": handleSolution(solution); break;
      }
   }

   function handleSolution(solution: Solution) {
      var updatedPuzzle: Cell[] = puzzle.map(c => {
         if (c.Type == "Given")
            return c;

         return { ...c, Type: "Value", SelectedValue: solution.Solution[c.Index] }
      });

      storeStateAndApplyChange(updatedPuzzle, false);
   }

   async function handleHint() {
      if (hint != null) {
         handleApplyHint(hint);
         return;
      }
      var newHint = await getHint(puzzle, puzzleSolution);
      setHint(newHint);
   }

   function handlePadAction(action: PadAction) {
      switch (action) {
         case "TogglePen": handleTogglePen(); break;
         case "Erase": handleEraseSelection(); break;
         case "FillAllCandidates": handleFillAllCandidates(); break;
         case "ClearCandidates": handleClearCollisions(); break;
         case "ToggleAutoClear": handleSetAutoClear(!autoClear); break;
         case "ToggleBlockCandidate": handleSetBlockCandidate(!blockCandidate); break;
         case "ClearSelection": handleClearSelection(); break;
         case "Undo": handleUndo(); break;
         case "Hint": handleHint(); break;
         case "Solve": handleSolve(); break;
      }
   }

   function handleChangePencilColor(color: PencilColor) {
      setPencilColor(color);
   }

   function handleFinishedClose() {
      setFinished(false);
      clearPuzzle();
      navigation.navigate("Lobby");
   }

   function handleApplyHint(hint: Hint) {
      switch (hint.Kind) {
         case "CorrectValueBlocked": unblockCandidate(hint.Change); break;
         case "InvalidValueSelected": applyInvalidValueSelected(hint); break;
         case "CandidateMissing": applyMissingCandidate(hint); break;

         case "SingleCandidate":
         case "HiddenSingle": applySetValueHint(hint.Change); break;

         case "UniqueAmongPeers":
         case "CandidateLine":
         case "DoublePair": // 080030070507802003000704080098000520600008004075000810800406000200907008760080040
         case "MultipleLines":
         case "NakedPair":
         case "BoxLineReduction":
         case "HiddenPair":
         case "NakedTriple":
         case "HiddenTriple":
         case "XWing":
         case "YWing":
         case "SimpleColoringRule2":
         case "SimpleColoringRule4":
         case "NakedQuad":
         case "HiddenQuad": applyRemoveHint(hint.Change); break;
      }
      setHint(null);
   }

   function applyMissingCandidate(change: CandidateMissing) {
      var updatedPuzzle = puzzle.map(cell => {
         if (cell.Type != "Candidates")
            return cell; // TODO throw exception?

         return (cell.Index == change.CellIndex)
            ? { ...cell, Candidates: cell.Candidates.concat([{ Type: "Available", Number: change.Candidate, Highlight: "None", Color: "Neutral", Collision: false, HintArrows: [] }]) }
            : cell;
      });

      storeStateAndApplyChange(updatedPuzzle, true);
   }

   function numberTakenByPeers(cell: CandidatesCell, puzzle: Cell[], candidate: number) {
      var takenByPeers = getHousesValues(cell, puzzle);
      var taken = takenByPeers.some(number => candidate == number);
      return taken;
   }

   function unblockCandidate(change: UnblockCandidateChange) {
      var updatedPuzzle = puzzle.map(cell => {
         if (cell.Type != "Candidates")
            return cell;

         if (!change.Cells.some(changeCell => cell.Column == changeCell.Column && cell.Row == changeCell.Row))
            return cell;

         var blocked = cell.Candidates.find(c => c.Number == change.Candidate);
         if (!blocked)
            return cell;

         var taken = numberTakenByPeers(cell, puzzle, change.Candidate);
         var unblocked: AvailableCandidate = { Type: "Available", Highlight: blocked.Highlight, Number: change.Candidate, Color: "Neutral", Collision: taken, HintArrows: [] };
         return { ...cell, Candidates: cell.Candidates.filter(c => change.Candidate != c.Number).concat([unblocked]) };
      });
      storeStateAndApplyChange(updatedPuzzle, false);
   }

   function applyRemoveHint(change: RemoveCandidatesChange) {
      var updatedPuzzle = puzzle.map(cell => {
         if (cell.Type != "Candidates")
            return cell;

         if (!change.Cells.some(change => change.Column == cell.Column && change.Row == cell.Row))
            return cell;

         var blocked: BlockedCandidate[] = change.RemoveCandidates.map(c => { return { Type: "Blocked", Highlight: "None", Number: c, HintArrows: [] } });
         var filteredCandidates = cell.Candidates.filter(c => !change.RemoveCandidates.includes(c.Number));
         return { ...cell, Candidates: filteredCandidates.concat(blocked) };
      });
      storeStateAndApplyChange(updatedPuzzle, false);
   }

   function applyInvalidValueSelected(hint: InvalidValueSelected) {
      var updatedPuzzle: Cell[] = puzzle.map(cell => {
         if (cell.Type != "Value")
            return cell;

         if (cell.Index != hint.CellIndex)
            return cell;

         const lastCandidates = getLastCandidates(cell, changeTrail);
         return { ...cell, Type: "Candidates", Candidates: lastCandidates };
      });
      storeStateAndApplyChange(updatedPuzzle, true);
   }

   function applySetValueHint(hint: SetValueChange) {
      var updatedPuzzle: Cell[] = puzzle.map(c => {
         if (!hint.Cells.some(cell => cell.Column == c.Column && c.Row == cell.Row))
            return c;

         return { ...c, Type: "Value", SelectedValue: hint.Value };
      });
      storeStateAndApplyChange(updatedPuzzle, true);
   }

   function handleCloseHint() {
      setHint(null);
   }

   function handleSelectionChange(selection: number[] | null) {
      var updatedPuzzle: Cell[] = puzzle.map(c => {
         return selection
            ? selection.some(index => c.Index == index)
               ? { ...c, Highlight: { Type: "Selected" } }
               : c
            : { ...c, Highlight: null }
      });

      setPuzzle(updatedPuzzle);
      if (selection && selection.length > 1 && pen == "Ink") {
         setPen("InkTemporaryPencil");
      }
   }

   return <View>
      <CompletionOverlay IsVisible={finished} OnCloseFn={handleFinishedClose}></CompletionOverlay>
      <Grid
         puzzle={puzzle}
         hint={hint}
         handleCellClick={handleCellClick}
         handleSelectionChange={handleSelectionChange}
      />
      <GridActions
         HandleAction={handlePadAction}
         AutoClear={autoClear}
         HandleNumberClick={handleNumberPadClick}
         Pen={pen}
         Highlight={taken}
         TakenValues={getSelectionTakenValues()}
         BlockCandidate={blockCandidate}
         SelectedPencilColor={pencilColor}
         SetPencilColor={handleChangePencilColor}
      />
      {
         hint != null
            ? <HintPanel
               Hint={hint}
               HandleApply={handleApplyHint}
               HandleClose={handleCloseHint}
            /> : null
      }
   </View>
}
