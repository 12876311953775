export type PuzzleApiModel = {
   Id: number,
   PuzzleSignature: string,
   SolutionSignature: string,
   Difficulty: number,
   TopTechnique: string,
   Symmetry: string | null,
   Average: number
}

export const fetchPuzzles = async (apiUrl: string, grade: number): Promise<PuzzleApiModel[]> => {
   try {
      const response = await fetch(`${apiUrl}/puzzle?majorGrade=${grade}`);
      return await response.json();
   } catch (error) {
      console.error(error);
      return []; // TODO Error handling
   }
}
