import { StyleSheet, View, Modal, TouchableOpacity, Image } from 'react-native';
import { Text } from './Themed';

export type CompletionOverlayProps = {
    IsVisible: boolean,
    OnCloseFn: () => void
}

export default function CompletionOverlay({ IsVisible, OnCloseFn }: CompletionOverlayProps): JSX.Element {

    function handleOkPress() {
        OnCloseFn();
    }

    const image = require('../assets/images/stars.png');

    return (
        <Modal animationType="slide" transparent={true} visible={IsVisible} style={styles.modal}>
            <View style={styles.centeredView}>
                <View style={styles.modalView}>
                    <Image
                        style={styles.stars}
                        source={image}
                    />
                    <Text style={styles.modalText}>Congratulations!</Text>
                    <TouchableOpacity style={styles.button} onPress={handleOkPress}>
                        <Text>Continue</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </Modal>
    );
}

const styles = StyleSheet.create({
    modal: {},
    stars: {
        width: 150,
        height: 70,
    },
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 22
    },
    button: {
        alignItems: "center",
        backgroundColor: "#DDDDDD",
        padding: 8,
        marginBottom: 5,
        marginTop: 5
    },
    modalView: {
        margin: 20,
        backgroundColor: "white",
        borderRadius: 20,
        padding: 35,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
    },
    modalText: {
        marginBottom: 15,
        textAlign: "center"
    }
});
