import React from 'react';
import PlayPanel from '../components/PlayPanel';
import { TouchableOpacity } from 'react-native';
import { Text, View } from '../components/Themed';
import { PlayScreenStyle } from '../styles/PlayScreenStyle';
import { RootStackScreenProps } from '../types';

export function PlayScreen({ route, navigation }: RootStackScreenProps<'Play'>) {
   const { puzzleSignature, puzzleId, puzzleSolution } = route.params;

   return (
      <View style={PlayScreenStyle.container}>
         <PlayPanel puzzleSignature={puzzleSignature} puzzleSolution={puzzleSolution} puzzleId={puzzleId} navigation={navigation} />
         <View style={PlayScreenStyle.bottomButtons}>
            <TouchableOpacity style={PlayScreenStyle.button} onPress={() => navigation.navigate('Lobby')}>
               <Text style={PlayScreenStyle.text}>{'<'}-Back</Text>
            </TouchableOpacity>
         </View>
      </View>
   );
}
