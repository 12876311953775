import { CorrectValueBlocked, Hint, InvalidValueSelected, SetValueChange, UnblockCandidateChange, fetchHint } from "../api/Hints";
import { CandidatesCell, Cell, ValueCell } from "../models/Cell";
import { getCellIndex, ghostCellIfNeeded } from "./Grid";

export function validateHint(hint: Hint, solution: string): boolean {
    switch (hint.Kind) {
        case "SingleCandidate":
        case "HiddenSingle": return validateChange(hint.Change, solution);
        default: return true;
    }
}

const noHint: Hint = { Kind: "HintNotFound", Details: [{ Message: "No hint found", Houses: [], Cells: [], Candidates: [], Arrows: [] }] };

export async function getHint(puzzle: Cell[], puzzleSolution: string): Promise<Hint> {
    // 1. Validate selected values and blocked candidates are correct
    const valueValidation = validatePuzzleValues(puzzle, puzzleSolution);
    if (valueValidation != null) {
        console.log("Validation failed");
        return valueValidation;
    }

    // TODO 2. Scanning techniques

    // 3. Server hints with ghosts
    var ghosted = puzzle.map(cell => ghostCellIfNeeded(cell, puzzleSolution, puzzle));
    const ghostedHint = await fetchHint(puzzleSolution, ghosted);
    if (ghostedHint && validateHint(ghostedHint, puzzleSolution)) {
        // Or how much should i update?
        return ghostedHint;
    }

    // 4. No hint found :/
    return noHint;
}

function validatePuzzleValues(puzzle: Cell[], solution: string): InvalidValueSelected | CorrectValueBlocked | null {
    for (var i = 0; i < solution.length - 1; i++) {
        var cell = puzzle[i];
        var expectation = parseInt(solution[i]);

        var hint = getHintForCell(cell, expectation);
        if (hint != null)
            return hint;
    }

    return null;
}

function getHintForCell(cell: Cell, expectation: number): InvalidValueSelected | CorrectValueBlocked | null {
    if (cell.Type == "Value")
        return validateSelectedCell(cell, expectation);
    else if (cell.Type == "Candidates")
        return validateBlockedCandidates(cell, expectation);

    return null;
}

function validateBlockedCandidates(cell: CandidatesCell, expectation: number): CorrectValueBlocked | null {
    var correctValueIsBlocked = cell.Candidates.some(c => c.Number == expectation && c.Type == "Blocked");
    if (correctValueIsBlocked) {
        const details = { Message: "The correct value for this cell is blocked", Houses: [], Cells: [cell], Candidates: [], Arrows: [] };
        const change: UnblockCandidateChange = { ChangeType: "UnblockCandidate", Cells: [cell], Candidate: expectation };
        return { Kind: "CorrectValueBlocked", Details: [details], Change: change }
    }

    return null;
}

function validateSelectedCell(cell: ValueCell, expectation: number): InvalidValueSelected | null {
    if (cell.SelectedValue != null && cell.SelectedValue != expectation) {
        const details = { Message: "The selected value for this cell is incorrect", Houses: [], Cells: [cell], Candidates: [], Arrows: [] };
        return { Kind: "InvalidValueSelected", Details: [details], CellIndex: cell.Index }
    }

    return null;
}

function validateChange(change: SetValueChange, solution: string): boolean {
    if (change.ChangeType != "SetValue") {
        return true;
    }

    var someInvalid = change.Cells.some(c => {
        var index = getCellIndex(c);
        var expectation = parseInt(solution[index]);
        return change.Value != expectation;
    });
    return !someInvalid;
}
