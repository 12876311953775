import React from 'react';
import { RootStackScreenProps } from '../types';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';

export function TestScreen({ route, navigation }: RootStackScreenProps<'Test'>) {

   return (
      <View style={styles.container}>
         <View style={styles.gridContainer}>
            {/* Render Sudoku grid here */}
            <Text style={styles.gridText}>Sudoku Grid</Text>
         </View>
         <View style={styles.buttonContainer}>
            {/* Render number buttons here */}
            <View style={styles.buttonRow}>
               <TouchableOpacity style={styles.button}>
                  <Text style={styles.buttonText}>1</Text>
               </TouchableOpacity>
               <TouchableOpacity style={styles.button}>
                  <Text style={styles.buttonText}>2</Text>
               </TouchableOpacity>
               <TouchableOpacity style={styles.button}>
                  <Text style={styles.buttonText}>3</Text>
               </TouchableOpacity>
            </View>
            <View style={styles.buttonRow}>
               <TouchableOpacity style={styles.button}>
                  <Text style={styles.buttonText}>4</Text>
               </TouchableOpacity>
               <TouchableOpacity style={styles.button}>
                  <Text style={styles.buttonText}>5</Text>
               </TouchableOpacity>
               <TouchableOpacity style={styles.button}>
                  <Text style={styles.buttonText}>6</Text>
               </TouchableOpacity>
            </View>
            <View style={styles.buttonRow}>
               <TouchableOpacity style={styles.button}>
                  <Text style={styles.buttonText}>7</Text>
               </TouchableOpacity>
               <TouchableOpacity style={styles.button}>
                  <Text style={styles.buttonText}>8</Text>
               </TouchableOpacity>
               <TouchableOpacity style={styles.button}>
                  <Text style={styles.buttonText}>9</Text>
               </TouchableOpacity>
            </View>
         </View>
      </View>
   );

};


const styles = StyleSheet.create({
   container: {
      flex: 1,
      backgroundColor: '#fff',
      padding: 20,
   },
   gridContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'lightgray',
   },
   gridText: {
      fontSize: 18,
      color: '#333',
   },
   buttonContainer: {
      justifyContent: 'center',
      alignItems: 'center',
   },
   buttonRow: {
      flexDirection: 'row',
      marginBottom: 10,
   },
   button: {
      backgroundColor: 'blue',
      paddingHorizontal: 16,
      paddingVertical: 10,
      borderRadius: 4,
      marginHorizontal: 5,
   },
   buttonText: {
      fontSize: 16,
   },
});