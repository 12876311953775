import { StyleSheet, TouchableOpacity } from 'react-native';
import { Text, View } from '../components/Themed';
import { RootStackScreenProps } from '../types';
import config from '../config';
import { useEffect, useState } from 'react';
import { PAGE_WIDTH } from '../styles/StyleVariables';
import GradeFilter from '../components/GradeFilter';
import { fetchPuzzles, PuzzleApiModel } from '../api/Puzzles';

export default function LobbyScreen({ navigation }: RootStackScreenProps<'Lobby'>) {

  const defaultPuzzleState: PuzzleApiModel[] = [];
  const [puzzles, setPuzzles] = useState(defaultPuzzleState);
  const [selectedGrade, setSelectedGrade] = useState(1)

  useEffect(() => {
    if (!config.API_URL)
      throw "Api url is not defined";

    fetchPuzzles(config.API_URL, selectedGrade)
      .then(puzzles => setPuzzles(puzzles));
  }, [selectedGrade]);

  const filterChanged = (newGrade: number) => {
    setSelectedGrade(newGrade);
  }

  return (
    <View style={styles.container}>
      <View style={styles.centerContainer}>
        <View style={styles.gradeSelect}>
          <GradeFilter OnValueChanged={filterChanged}></GradeFilter>
        </View>
        <View style={styles.puzzleList}>
          {
            puzzles.map((puzzle, i) => {
              return <View key={i} style={[styles.puzzleHolder, i % 2 == 0 ? styles.odd : {}]}>
                <Text style={styles.description}># {puzzle.Id}</Text>
                <Text style={styles.description}>Difficulty: {puzzle.Difficulty}</Text>
                <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('Play', { puzzleId: puzzle.Id, puzzleSolution: puzzle.SolutionSignature, puzzleSignature: puzzle.PuzzleSignature })}>
                  <Text>Play</Text>
                </TouchableOpacity>
              </View>
            })
          }
        </View>
        <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('Main')}>
          <Text>Back</Text>
        </TouchableOpacity>
      </View>
    </View >
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    paddingTop: 60,
    backgroundColor: "rgba(0,0,0,1)"
  },
  gradeSelect: {},
  centerContainer: {
    width: PAGE_WIDTH,
    textAlign: 'center',
    backgroundColor: "rgba(0,0,0,1)"
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 20
  },
  puzzleList: {
    marginTop: 30
  },
  puzzleHolder: {
    alignItems: "center",
    flexDirection: "row",
  },
  odd: {
    backgroundColor: "rgba(125, 125, 125, 0.2)",
    color: "green"
  },
  description: {
    flex: 1,
    marginRight: 5,
    textAlign: 'left'
  },
  play: {
    flex: 1,
    textAlign: 'right'
  },
  button: {
    alignItems: "center",
    backgroundColor: "#DDDDDD",
    padding: 10,
  },
});
