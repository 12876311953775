import config from "../config";
import { Cell } from "../models/Cell";
import { PuzzleRequestCellData, getApiPuzzleRequestModel } from "./ApiModels";

export type SolutionApiModel = {
   Solved: boolean,
   Solution: number[]
}

export type SolvePuzzleResult =
   | Solution
   | NoSolution

export type NoSolution = {
   Kind: "NoSolution"
}

export type Solution = {
   Kind: "Solution"
   Solution: number[]
}

export type SolutionRequestModel = {
   Puzzle: PuzzleRequestCellData[]
}

export async function fetchSolution(puzzle: Cell[]): Promise<SolvePuzzleResult | null> {
   var requestModel = getPuzzleRequestModel(puzzle);
   const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestModel)
   };

   try {
      const response = await fetch(`${config.API_URL}/solution`, requestOptions);
      const json = await response.json();
      if (json == null)
         return null;
      else
         return convertToSolution(json);
   } catch (error) {
      console.error(error);
      return Promise.reject(error);
   }
}

function getPuzzleRequestModel(puzzle: Cell[]): SolutionRequestModel {
   return {
      Puzzle: puzzle.map(getApiPuzzleRequestModel)
   }
}

function convertToSolution(solution: SolutionApiModel): SolvePuzzleResult {
   return solution.Solved ?
      { Kind: "Solution", Solution: solution.Solution }
      : { Kind: "NoSolution" }
}
