import { ArrowDirection, AvailableCandidate, Candidate, CandidateHighlight, CandidatesCell, Cell, CellPosition, HintArrow, MarkBorderColor, MarkBorders } from "../models/Cell";
import { PencilColor } from "../models/PencilColor";
import { CreateCell } from "./Cell";
import { Highlight } from "../models/Highlight";
import { PencilType } from "../models/PencilType";
import { ArrowCandidate, CandidateReferenceApiModel, Hint, HintArrowApiModel, HouseType } from "../api/Hints";

const allNumber: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9];
const candidateAvailable: AvailableCandidate = { Type: "Available", Number: 1, Color: "Neutral", Highlight: "None", Collision: false, HintArrows: [] }

export function fillAllCandidatesInCell(cell: Cell, puzzle: Cell[]): Cell {
   switch (cell.Type) {
      case "Candidates": return fillCandidates(cell, puzzle);
      case "Value":
      case "Given": return cell;
   }
}

function fillCandidates(cell: CandidatesCell, puzzle: Cell[]): Cell {
   var candidates = allCandidates.map(newCandidate => {
      var existing = cell.Candidates.filter(existing => newCandidate.Number == existing.Number).pop();
      return existing ? existing : getCandidateWithCollision(cell, newCandidate, puzzle);
   });

   return { ...cell, Candidates: candidates };
}

function getCandidateWithCollision(cell: CandidatesCell, candidate: AvailableCandidate, puzzle: Cell[]): Candidate {
   return { ...candidate, Collision: getCandidateCollision(candidate.Number, cell, puzzle) };
}

export const allCandidates: AvailableCandidate[] = [
   { ...candidateAvailable, Number: 1 },
   { ...candidateAvailable, Number: 2 },
   { ...candidateAvailable, Number: 3 },
   { ...candidateAvailable, Number: 4 },
   { ...candidateAvailable, Number: 5 },
   { ...candidateAvailable, Number: 6 },
   { ...candidateAvailable, Number: 7 },
   { ...candidateAvailable, Number: 8 },
   { ...candidateAvailable, Number: 9 }];

export type CandiatesChanged = {
   Kind: "Candidates"
   CellIndex: number
   BeforeCandidates: Candidate[]
   AfterCandidates: Candidate[]
}

export type ValueChanged = {
   Kind: "Value"
   CellIndex: number
   BeforeValue: number | null
   BeforeCandidates: Candidate[]
   AfterValue: number | null
}

export type Change = {
   Changes: CellChange[]
   SelectionIds: number[]
};

export type CellChange = CandiatesChanged | ValueChanged;

export type PuzzleChange = {
   Changes: CellChange[]
   UpdatedPuzzle: Cell[]
}

// We include the current selection here also so we can restore this aswell.
export function getChangeTrail(highlight: Highlight, changes: CellChange[], changeTrail: Change[]): Change[] {
   const selection = highlight?.Kind == "selection" ? highlight.Numbers : [];
   const change: Change = {
      Changes: changes,
      SelectionIds: selection.concat()
   };
   changeTrail.push(change);
   return changeTrail;
}

export function getClickPenChanges(puzzle: Cell[], pen: PencilType, pencilColor: PencilColor, blockCandidate: boolean, clickNumber: number): Cell[] {
   switch (pen) {
      case "Ink": return writeInkInSelection(puzzle, clickNumber);
      default: return writePencilInSelection(puzzle, clickNumber, pencilColor, blockCandidate);
   }
}

export function updateCandidateCollisions(updatedPuzzle: Cell[]) {
   updatedPuzzle = updatedPuzzle.map(cell => {
      if (cell.Type != "Candidates")
         return cell;

      var taken = getHousesValues(cell, updatedPuzzle);

      // Find candidates which needs to be set to collision
      var needsUpdate = cell.Candidates.filter(c => taken.some(t => t == c.Number && c.Type != "Blocked"));
      if (needsUpdate.length == 0)
         return cell;

      // Update those.
      var updated = needsUpdate.map(c => { return { ...c, Collision: true }; });

      var old = cell.Candidates.filter(o => !updated.some(u => u.Number == o.Number));
      return { ...cell, Candidates: old.concat(updated) };
   });
   return updatedPuzzle;
}

// TODO write tests for these
// R1 C5 = 5 + ((1 - 1) * 9) - 1 = 5 + 0 - 1 = 4
// R2 C3 = 3 + ((2 - 1) * 9) - 1 = 3 + 9 - 1 = 11
// R9 C9 = 9 + ((9 - 1) * 9) - 1 = 9 + 72 - 1 = 80
export function getCellIndex(cellPosition: CellPosition): number {
   return (cellPosition.Column) + ((cellPosition.Row - 1) * 9) - 1;
}

export function getCellPosition(index: number): CellPosition {
   var column = (index % 9) + 1;
   var row = Math.floor(index / 9) + 1;
   return { Row: row, Column: column };
}

export function createPuzzleFromSignature(signature: string): Cell[] {
   const givens = signature.split('');
   if (givens == null || givens.length != 81)
      throw `Cannot parse ${signature} as 81 cells`;

   return givens.map((given, index) => CreateCell(index, parseInt(given), null));
}

export function getCellSelectionTakenValues(puzzle: Cell[]) {
   if (puzzle.some(c => c.Highlight?.Type == "Selected" && c.Type == "Given")) { return allNumber };

   const selection = puzzle.filter(c => c.Highlight?.Type == "Selected");
   const columns = selection.map(c => indexColumn[c.Index]).filter(distinct);
   const rows = selection.map(c => indexRow[c.Index]).filter(distinct);
   const houses = selection.map(c => indexBox[c.Index]).filter(distinct);
   const blockedValues = selection.flatMap(c => c.Type == "Candidates" ? c.Candidates.filter(c => c.Type == "Blocked").map(c => c.Number) : []);

   return columns.flatMap(c => getColumnValues(c, puzzle))
      .concat(rows.flatMap(c => getRowValues(c, puzzle)))
      .concat(houses.flatMap(c => getBoxValues(c, puzzle)))
      .concat(blockedValues)
      .filter(distinct);
}

export function writeInkInSelection(puzzle: Cell[], writeNumber: number): Cell[] {
   return puzzle.map(c => {
      switch (c.Type) {
         case "Candidates":
         case "Value": return c.Highlight?.Type == "Selected" ? { ...c, Type: "Value", SelectedValue: writeNumber } : c;
         case "Given": return c;
      }
   });
}

export function writePencilInSelection(puzzle: Cell[], writeNumber: number, color: PencilColor, block: boolean): Cell[] {
   // Look at selected cells if they already have the candidate with correct number, block and color
   const candidatesForCells = puzzle
      .filter(c => c.Highlight?.Type == "Selected" && c.Type == "Candidates")
      .map(c => c.Type == "Candidates" ? c.Candidates : [])
      .map(cellCandidates => cellCandidates.some(candidate => candidate.Number == writeNumber && (candidate.Type == "Blocked") == block && ((candidate.Type == "Available" && candidate.Color == color) || candidate.Type != "Available")));

   return (candidatesForCells.every(found => found)) // If all candidates exists for all cells with correct blocked
      ? puzzle.map(c => removeCandidateFromSelectedCell(c, writeNumber))
      : puzzle.map(c => addCandidateForSelectedCell(c, writeNumber, color, block, puzzle));
}

function removeCandidateFromSelectedCell(cell: Cell, removeNumber: number): Cell {
   return cell.Highlight?.Type == "Selected" && cell.Type == "Candidates"
      ? { ...cell, Candidates: cell.Candidates.filter(c => c.Number != removeNumber) }
      : cell;
}

function pluckCandidate(cell: CandidatesCell, candidate: number): { rest: Candidate[], pluck: Candidate | undefined } {
   var rest = cell.Candidates.filter(c => c.Number != candidate);
   var plucked = cell.Candidates.filter(c => c.Number == candidate).pop();
   return { rest: rest, pluck: plucked };
}

function getCellMarkBorders(index: number): MarkBorders {
   return {
      Index: index,
      Type: "Cell",
      Top: true,
      Right: true,
      Bottom: true,
      Left: true,
   }
}

export function addGhostsAndHintMarks(hint: Hint, puzzle: Cell[], puzzleSolution: string): Cell[] {
   var updatedPuzzle = addGhosts(hint, puzzle, puzzleSolution);
   updatedPuzzle = markHouses(hint, updatedPuzzle);
   updatedPuzzle = markCells(hint, updatedPuzzle);
   updatedPuzzle = markCandidates(hint, updatedPuzzle);
   updatedPuzzle = markArrows(hint, updatedPuzzle);
   return updatedPuzzle;
}

function markArrows(hint: Hint, updatedPuzzle: Cell[]) {
   hint.Details.filter(x => x.Arrows != null && x.Arrows.length > 0).forEach((detail, _) => {
      var candidateHighlights = detail.Arrows.flatMap(a => [a.End, a.Start]);

      updatedPuzzle = candidateHighlights.reduce((previous: Cell[], current: ArrowCandidate, _, __) => {
         var index = getCellIndex(current.Cell);
         var cell = previous[index];
         if (cell.Type != "Candidates")
            return previous;

         var { rest, pluck } = pluckCandidate(cell, current.Candidate);
         if (!pluck)
            return previous;

         var updated: Candidate = { ...pluck, Highlight: current.Color ? "Color1" : "Color2" };
         previous[index] = { ...cell, Candidates: rest.concat([updated]) };
         return previous;
      }, updatedPuzzle);

      updatedPuzzle = detail.Arrows.reduce((previous: Cell[], current: HintArrowApiModel, _, __) => {
         var endIndex = getCellIndex(current.End.Cell);
         var startIndex = getCellIndex(current.Start.Cell);
         var newPuzzle = previous.concat();
         var { direction, cell, candidate }: { direction: ArrowDirection; cell: Cell; candidate: number; } = endIndex > startIndex
            ? { direction: "Incoming", cell: newPuzzle[endIndex], candidate: current.Start.Candidate }
            : { direction: "Outgoing", cell: newPuzzle[startIndex], candidate: current.Start.Candidate };

         if (cell.Type != "Candidates") {
            return previous;
         }

         var oldCandidate = cell.Candidates.filter(c => c.Number == candidate).pop();
         if (!oldCandidate) {
            return previous;
         }

         var candidates = cell.Candidates.filter(c => c.Number != candidate);
         var arrow: HintArrow = { Type: direction, Start: current.Start, End: current.End };
         var newCandidate: Candidate = { ...oldCandidate, HintArrows: oldCandidate.HintArrows.concat(arrow) };
         var updated: Cell = { ...cell, Candidates: candidates.concat(newCandidate) };
         newPuzzle[cell.Index] = updated;

         return newPuzzle;
      }, updatedPuzzle);
   });
   return updatedPuzzle;
}

function markCandidates(hint: Hint, updatedPuzzle: Cell[]) {
   hint.Details.filter(x => x.Candidates != null && x.Candidates.length > 0).forEach((detail, i) => {
      // Get all affected cells
      var markCells = detail.Candidates.map(c => updatedPuzzle[getCellIndex(c.Cell)]);

      updatedPuzzle = updatedPuzzle.map(cell => {
         if (cell.Type != "Candidates")
            return cell;

         if (!markCells.some(x => x.Index == cell.Index))
            return cell;

         // Find the candidates which should be marked
         var candidates = cell.Candidates.filter(candidate => detail.Candidates.some(x => x.Candidate == candidate.Number));
         if (candidates == null || candidates.length == 0)
            return cell;

         var existing = cell.Candidates.filter(candidate => !detail.Candidates.some(x => x.Candidate == candidate.Number));
         var updatedCandidates: Candidate[] = candidates.map(x => { return { ...x, Highlight: getCandiateHighlight(x, i, detail.Candidates) }; });

         return { ...cell, Candidates: existing.concat(updatedCandidates) };
      });
   });
   return updatedPuzzle;
}

function markCells(hint: Hint, updatedPuzzle: Cell[]) {
   hint.Details.forEach(detail => {
      if (!detail.Cells || detail.Cells.length == 0)
         return;

      // Loop each reference
      var houseCells = detail.Cells.map(c => updatedPuzzle[getCellIndex(c)]);
      // Find max existing index
      var nextBorder = Math.max(...houseCells.flatMap(c => c.HintMarkBorders.map(m => m.Index)).concat([0])) + 1;

      // Create new mark
      var newMark = getCellMarkBorders(nextBorder);
      updatedPuzzle = updatedPuzzle.map(c => {
         if (!houseCells.some(x => x.Index == c.Index))
            return c;

         return { ...c, HintMarkBorders: c.HintMarkBorders.concat(newMark) };
      });
   });
   return updatedPuzzle;
}

function markHouses(hint: Hint, updatedPuzzle: Cell[]) {
   hint.Details.forEach(detail => {
      // Loop each house reference
      detail.Houses.forEach(house => {
         // Find all affected cells
         var cellIndexes = getCellIndexesInHouse(house.HouseIndex, house.HouseType);
         var houseCells = cellIndexes.map(c => updatedPuzzle[c]);

         // What is the max house border count for these
         var nextBorder = Math.max(...houseCells.flatMap(c => c.HintMarkBorders.map(m => m.Index)).concat([0])) + 1;

         var positions = cellIndexes.map(i => getCellPosition(i));
         // Create new border with count = oldMax + 1
         var columns = positions.map(p => p.Column);
         var row = positions.map(p => p.Row);

         // Get min max column and row
         var minColumn = Math.min(...columns);
         var maxColumn = Math.max(...columns);
         var minRow = Math.min(...row);
         var maxRow = Math.max(...row);

         // TODO We haveto iterate alot here. Know what indexes we should replace, can target them directly?
         // Replace with details.reduce
         updatedPuzzle = updatedPuzzle.map(c => {
            if (!cellIndexes.some(i => i == c.Index))
               return c;

            var newBorder: MarkBorders = {
               Index: nextBorder,
               Type: "House",
               Top: c.Row == minRow && c.Column >= minColumn && c.Column <= maxColumn,
               Right: c.Column == maxColumn && c.Row >= minRow && c.Row <= maxRow,
               Bottom: c.Row == maxRow && c.Column >= minColumn && c.Column <= maxColumn,
               Left: c.Column == minColumn && c.Row >= minRow && c.Row <= maxRow,
            };

            return { ...c, HintMarkBorders: c.HintMarkBorders.concat(newBorder) };
         });
      });

   });
   return updatedPuzzle;
}

function addGhosts(hint: Hint, puzzle: Cell[], puzzleSolution: string) {
   var cells = hint.Details.flatMap(h => h.Cells.map(c => getCellIndex(c)));
   var houseCells = hint.Details.flatMap(h => h.Houses.flatMap(house => getCellIndexesInHouse(house.HouseIndex, house.HouseType)));
   var candidateCells = hint.Details.flatMap(h => h.Candidates.flatMap(c => getCellIndex(c.Cell)));
   var arrowCells = hint.Details.flatMap(h => h.Arrows.flatMap(a => [getCellIndex(a.Start.Cell), getCellIndex(a.End.Cell)]));

   var allGhostedCellIndexes = cells.concat(houseCells).concat(candidateCells).concat(arrowCells);

   var updatedPuzzle = puzzle.concat();
   allGhostedCellIndexes.map(c => {
      updatedPuzzle[c] = ghostCellIfNeeded(updatedPuzzle[c], puzzleSolution, puzzle);
   });
   return updatedPuzzle;
}

function getCandiateHighlight(candidate: Candidate, i: number, details: CandidateReferenceApiModel[]): CandidateHighlight {
   var detail = details.filter(c => c.Candidate == candidate.Number).pop();
   if (!detail)
      return "None";

   if (detail.Remove)
      return "Remove";

   switch (i) {
      case 0: return "Color1";
      case 1: return "Color2";
      case 2: return "Color3";
      case 3: return "Color4";
      default: return "Color1";
   }
}

export function removeTakenHighlightCell(cell: Cell): Cell {
   switch (cell.Type) {
      case "Candidates": return { ...cell, Highlight: cell.Highlight?.Type == "Taken" ? null : cell.Highlight, Candidates: cell.Candidates.map(c => { return { ...c, Highlight: "None" } }) };
      case "Value": return { ...cell, Highlight: null }; // TODO maybe find previous highlight?
      case "Given": return { ...cell, Highlight: null }; // TODO maybe find previous highlight?
   }
}

export function maybeGetAutoClearChanges(updatedPuzzle: Cell[]): Cell[] {
   return getAutoClearChanges(updatedPuzzle);
}

function highlightCandidate(cell: CandidatesCell, number: number): Cell {
   var candidates = cell.Candidates.map(c => { return { ...c, Highlight: getHighlight(c, number) } });
   return { ...cell, Candidates: candidates };
}

function getHighlight(c: Candidate, number: number): CandidateHighlight {
   return (c.Number == number && c.Type != "Blocked") ? "Color1" : "None";
}

export function highlightCandidates(cell: Cell, number: number): Cell {
   switch (cell.Type) {
      case "Candidates": return highlightCandidate(cell, number);
      case "Value": return cell;
      case "Given": return cell;
   };
}

export function removeSelection(cell: Cell): Cell {
   switch (cell.Highlight?.Type) {
      case "Selected": return { ...cell, Highlight: null }
      default: return cell;
   }
}

export function getBlockedCandidates(cell: Cell): number[] {
   if (cell.Type != "Candidates")
      return [];

   return cell.Candidates.filter(c => c.Type == "Blocked").map(c => c.Number);
}

export function getLastCandidates(cell: Cell, trail: Cell[][]): Candidate[] {
   const previousCandidates = trail.findLast(t => {
      var prev = t[cell.Index];
      return prev.Type == "Candidates" && prev.Candidates.length > 0;
   });

   if (!previousCandidates)
      return [];

   const prev = previousCandidates[cell.Index]
   if (prev.Type != "Candidates")
      return [];

   return prev.Candidates;
}

export function removeHighlightAndGhosts(cell: Cell): Cell {
   switch (cell.Type) {
      case "Candidates": return { ...cell, Highlight: null, HintMarkBorders: [], Candidates: removeGhostsAndMarks(cell.Candidates) };
      case "Given":
      case "Value": return { ...cell, Highlight: null, HintMarkBorders: [] };
   }
}

function removeGhostsAndMarks(candidates: Candidate[]): Candidate[] {
   return candidates
      .filter(candidate => candidate.Type != "Ghost")
      .map(c => { return { ...c, Highlight: "None" } });
}

export function ghostCellIfNeeded(cell: Cell, solution: string, puzzle: Cell[]) {
   if (cell.Type != "Candidates")
      return cell;

   var expected = parseInt(solution[cell.Index]);
   if (cell.Candidates.some(candidate => candidate.Number == expected && candidate.Type == "Available"))
      return cell;

   // From all candidate, remove "taken by peers" and those which already exists.
   var taken = getHousesValues(cell, puzzle);
   var fill: Candidate[] = allCandidates
      .filter(candidate => !taken.some(x => x == candidate.Number) && !cell.Candidates.some(x => x.Number == candidate.Number))
      .map(c => { return { ...c, Type: "Ghost" } });

   return { ...cell, Candidates: cell.Candidates.concat(fill) };
}

function getCellIndexesInHouse(houseIndex: number, houseType: HouseType): number[] {
   if (houseIndex > 8)
      throw `Index cannot be ${houseIndex}`;

   switch (houseType) {
      case HouseType.Box: return boxIndexes[houseIndex];
      case HouseType.Column: return columnIndexes[houseIndex];
      case HouseType.Row: return rowIndexes[houseIndex];
   }
}

function addCandidateForSelectedCell(cell: Cell, number: number, color: PencilColor, block: boolean, puzzle: Cell[]): Cell {
   return cell.Type == "Candidates" && cell.Highlight?.Type == "Selected"
      ? { ...cell, Candidates: getNewCandidatesForCellIfMissing(cell, number, color, block, puzzle) }
      : cell;
}

function getNewCandidatesForCellIfMissing(cell: CandidatesCell, number: number, color: PencilColor, block: boolean, puzzle: Cell[]): Candidate[] {
   return cell.Candidates.some(c => c.Number == number && (c.Type == "Blocked") == block && hasCorrectColor(c, color))
      ? cell.Candidates
      : getNewCandidatesForCell(cell, number, color, block, puzzle);
}

function hasCorrectColor(candidate: Candidate, color: PencilColor): boolean {
   return ((candidate.Type == "Available" && candidate.Color == color) || candidate.Type != "Available");
}

function getNewCandidatesForCell(cell: CandidatesCell, number: number, color: PencilColor, block: boolean, puzzle: Cell[]): Candidate[] {
   return cell.Candidates.filter(c => c.Number != number).concat([getCandidate(number, color, block, cell, puzzle)]);
}

function getCandidate(number: number, color: PencilColor, block: boolean, cell: Cell, puzzle: Cell[]): Candidate {
   switch (block) {
      case true: return { Type: "Blocked", Number: number, Highlight: "None", HintArrows: [] };
      case false: return { Type: "Available", Number: number, Color: color, Highlight: "None", Collision: getCandidateCollision(number, cell, puzzle), HintArrows: [] };
   }
}

function getCandidateCollision(candidate: number, cell: Cell, puzzle: Cell[]): boolean {
   var taken = getHousesValues(cell, puzzle);
   return taken.some(a => a == candidate);
}

export function getNewSelectionTrail(selectionIndexes: number[], puzzle: Cell[], cellWidth: number, locationX: number, locationY: number)
   : number[] {
   const margins = Math.ceil(cellWidth / 5);
   const xRest = locationX % cellWidth;
   const yRest = locationY % cellWidth;
   const endCellSelection = (cellWidth - margins);


   if ((xRest < margins && yRest < margins) // Top left
      || (xRest > endCellSelection && yRest < margins)
      || (xRest < margins && yRest > endCellSelection)
      || (xRest > endCellSelection && yRest > endCellSelection)) {
      return selectionIndexes;
   };

   const currentColumn = Math.ceil(locationX / cellWidth);
   const currentRow = Math.ceil(locationY / cellWidth);

   const minIndex = Math.min(currentColumn, currentRow);
   const maxIndex = Math.max(currentColumn, currentRow);
   if (minIndex < 1 || maxIndex > 9) { return selectionIndexes; }

   const currentIndex = rowIndexes[currentRow - 1][currentColumn - 1];

   //If this is same cell as last then ignore this movement
   if (selectionIndexes.at(-1) == currentIndex) {
      return selectionIndexes;
   }

   const currentCell = puzzle[currentIndex];

   // If this cell is second to last then remove last
   if (selectionIndexes.at(-2) == currentCell.Index) {
      return selectionIndexes.slice(0, selectionIndexes.length - 1);
   } else {
      return selectionIndexes.concat([currentCell.Index]);
   }
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
   return value !== null && value !== undefined;
}

function distinct<T>(value: T, index: number, array: T[]): boolean {
   return array.indexOf(value) === index;
}

function getRowValues(row: number, puzzle: Cell[]): number[] {
   return rowIndexes[row - 1]
      .map(index => {
         var columnNeighbour = puzzle[index];
         return getCellGivenOrValue(columnNeighbour);
      })
      .filter(notEmpty);
}

function getColumnValues(column: number, puzzle: Cell[]): number[] {
   return columnIndexes[column - 1]
      .map(index => {
         var columnNeighbour = puzzle[index];
         return getCellGivenOrValue(columnNeighbour);
      })
      .filter(notEmpty);
}

function getCellGivenOrValue(cell: Cell): number | null {
   switch (cell.Type) {
      case "Candidates": return null;
      case "Given": return cell.Given;
      case "Value": return cell.SelectedValue;
   }
}

function getBoxValues(box: number, puzzle: Cell[]): number[] {
   return puzzle // TODO Change to only loop box
      .filter(cell => cell.Box == box)
      .map(cell => getCellGivenOrValue(cell))
      .filter(notEmpty);
}

export function getHousesValues(Cell: Cell, puzzle: Cell[]): number[] {
   return getRowValues(Cell.Row, puzzle)
      .concat(getColumnValues(Cell.Column, puzzle))
      .concat(getBoxValues(Cell.Box, puzzle))
      .filter(distinct);
}

export function getAutoClearChanges(updatedPuzzle: Cell[]): Cell[] {
   return updatedPuzzle.map(c => {
      if (c.Type == "Given" || c.Type == "Value") {
         return c;
      };

      var neighbourValues = getHousesValues(c, updatedPuzzle);

      const candidates = c.Candidates.filter(candidate => !neighbourValues.some(c2 => c2 == candidate.Number));
      return (c.Candidates.length != candidates.length)
         ? { ...c, Candidates: candidates }
         : c;
   });
}

// Index i array ska vara boxen. Så på pos 1 ska cell index för alla celler i box 1 finnas. 0,2,3,9,10,11

export const indexBox: number[] = [
   1, 1, 1, 2, 2, 2, 3, 3, 3,
   1, 1, 1, 2, 2, 2, 3, 3, 3,
   1, 1, 1, 2, 2, 2, 3, 3, 3,
   4, 4, 4, 5, 5, 5, 6, 6, 6,
   4, 4, 4, 5, 5, 5, 6, 6, 6,
   4, 4, 4, 5, 5, 5, 6, 6, 6,
   7, 7, 7, 8, 8, 8, 9, 9, 9,
   7, 7, 7, 8, 8, 8, 9, 9, 9,
   7, 7, 7, 8, 8, 8, 9, 9, 9,
];

export const indexColumn: number[] = [
   1, 2, 3, 4, 5, 6, 7, 8, 9,
   1, 2, 3, 4, 5, 6, 7, 8, 9,
   1, 2, 3, 4, 5, 6, 7, 8, 9,
   1, 2, 3, 4, 5, 6, 7, 8, 9,
   1, 2, 3, 4, 5, 6, 7, 8, 9,
   1, 2, 3, 4, 5, 6, 7, 8, 9,
   1, 2, 3, 4, 5, 6, 7, 8, 9,
   1, 2, 3, 4, 5, 6, 7, 8, 9,
   1, 2, 3, 4, 5, 6, 7, 8, 9,
];

export const indexRow: number[] = [
   1, 1, 1, 1, 1, 1, 1, 1, 1,
   2, 2, 2, 2, 2, 2, 2, 2, 2,
   3, 3, 3, 3, 3, 3, 3, 3, 3,
   4, 4, 4, 4, 4, 4, 4, 4, 4,
   5, 5, 5, 5, 5, 5, 5, 5, 5,
   6, 6, 6, 6, 6, 6, 6, 6, 6,
   7, 7, 7, 7, 7, 7, 7, 7, 7,
   8, 8, 8, 8, 8, 8, 8, 8, 8,
   9, 9, 9, 9, 9, 9, 9, 9, 9
];

export const columnIndexes: number[][] = [
   [0, 9, 18, 27, 36, 45, 54, 63, 72],
   [1, 10, 19, 28, 37, 46, 55, 64, 73],
   [2, 11, 20, 29, 38, 47, 56, 65, 74],
   [3, 12, 21, 30, 39, 48, 57, 66, 75],
   [4, 13, 22, 31, 40, 49, 58, 67, 76],
   [5, 14, 23, 32, 41, 50, 59, 68, 77],
   [6, 15, 24, 33, 42, 51, 60, 69, 78],
   [7, 16, 25, 34, 43, 52, 61, 70, 79],
   [8, 17, 26, 35, 44, 53, 62, 71, 80]
];

export const rowIndexes: number[][] = [
   [0, 1, 2, 3, 4, 5, 6, 7, 8],
   [9, 10, 11, 12, 13, 14, 15, 16, 17],
   [18, 19, 20, 21, 22, 23, 24, 25, 26],
   [27, 28, 29, 30, 31, 32, 33, 34, 35],
   [36, 37, 38, 39, 40, 41, 42, 43, 44],
   [45, 46, 47, 48, 49, 50, 51, 52, 53],
   [54, 55, 56, 57, 58, 59, 60, 61, 62],
   [63, 64, 65, 66, 67, 68, 69, 70, 71],
   [72, 73, 74, 75, 76, 77, 78, 79, 80]
];

export const boxIndexes: number[][] = [
   [0, 1, 2, 9, 10, 11, 18, 19, 20],
   [3, 4, 5, 12, 13, 14, 21, 22, 23],
   [6, 7, 8, 15, 16, 17, 24, 25, 26],
   [27, 28, 29, 36, 37, 38, 45, 46, 47],
   [30, 31, 32, 39, 40, 41, 48, 49, 50],
   [33, 34, 35, 42, 43, 44, 51, 52, 53],
   [54, 55, 56, 63, 64, 65, 72, 73, 74],
   [57, 58, 59, 66, 67, 68, 75, 76, 77],
   [60, 61, 62, 69, 70, 71, 78, 79, 80]
];