import { useEffect, useState } from 'react';
import { FlatList, StyleSheet, NativeSyntheticEvent, NativeScrollEvent, TouchableOpacity } from 'react-native';
import { Text, View } from '../components/Themed';
import useDebounce from '../hooks/useDebounce';
import { PAGE_WIDTH } from '../styles/StyleVariables';

const GRADE_ITEM_COUNT = 9;
const GRADE_ITEM_WIDTH = PAGE_WIDTH / GRADE_ITEM_COUNT;

export type GradeFilterProps = {
  OnValueChanged: (NewGrade: number) => void,
}

export default function GradeFilter({ OnValueChanged }: GradeFilterProps): JSX.Element {
  const [selectedGrade, setSelectedGrade] = useState(1);
  const debouncedValue = useDebounce<number>(selectedGrade, 110);

  useEffect(() => {
    OnValueChanged(debouncedValue);
  }, [debouncedValue]);

  type GradeListItem = {
    Dummy: boolean,
    Major: number
  }

  const getTextCss = (grade: GradeListItem) => {
    switch (getDistanceFromSelected(grade)) {
      case 0: return [styles.gradeSelected];
      case 1: return [styles.fade1, styles.gradeNotSelected];
      case 2: return [styles.fade2, styles.gradeNotSelected];
      case 3: return [styles.fade3, styles.gradeNotSelected];
      default: return [styles.fadex, styles.gradeNotSelected];
    }
  }

  const getDistanceFromSelected = (grade: GradeListItem): number => {
    return Math.abs(grade.Major - selectedGrade);
  }

  const renderItem = (Item: GradeListItem) => {
    return Item.Dummy
      ? <View style={[styles.filterItem, styles.filterItemDummy]}></View>
      : <TouchableOpacity onPress={_ => selectGrade(Item)}><View style={[styles.filterItem]}><Text style={getTextCss(Item)}>{Item.Major}</Text></View></TouchableOpacity>;
  }

  const selectGrade = (grade: GradeListItem) => {
    flatListRef?.scrollToIndex({ animated: true, index: grade.Major - 1 });
  }

  const getGrades = (): GradeListItem[] => {
    return Array.from(Array(7).keys()).flatMap(major => {
      return {
        Dummy: false,
        Major: major + 1
      };
    });
  }

  const dummyValue: GradeListItem = {
    Dummy: true,
    Major: 0
  };

  const handleGradeScroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    const currentPositionX = e.nativeEvent.contentOffset.x;
    const itemIndex = Math.round(currentPositionX / GRADE_ITEM_WIDTH);
    setSelectedGrade(itemIndex + 1);
  }

  const getDummies = (start: number): GradeListItem[] => {
    return Array.from(Array(Math.floor(GRADE_ITEM_COUNT / 2)).keys()).map(i => { return { ...dummyValue, Major: start - i } });
  }

  const getExtendedGrades = (): GradeListItem[] => {
    const grades = getGrades();
    return [
      ...getDummies(0),
      ...grades,
      ...getDummies(99)
    ]
  }
  var flatListRef: FlatList<GradeListItem> | null = null;

  return <FlatList style={styles.gradeFilter}
    ref={(ref) => { flatListRef = ref; }}
    data={getExtendedGrades()}
    onScroll={handleGradeScroll}
    showsHorizontalScrollIndicator={false}
    renderItem={({ item }) => renderItem(item)}
    keyExtractor={(item, _) => `${item.Major}`}
    horizontal={true}
    snapToAlignment='start'
    snapToInterval={GRADE_ITEM_WIDTH}
    decelerationRate={"fast"}
    initialScrollIndex={0}
  />
}

const styles = StyleSheet.create({
  gradeFilter: {
    backgroundColor: "rgba(0,0,0,1)"
  },
  filterItem: {
    width: GRADE_ITEM_WIDTH,
    justifyContent: 'center',
    alignItems: 'center',
  },
  filterItemDummy: {
  },
  gradeNotSelected: {
    fontSize: 22,
  },
  gradeSelected: {
    color: "rgba(255, 255, 255, 1)",
    fontSize: 35,
    flexWrap: "nowrap"
  },
  fade1: {
    color: "rgba(255, 255, 255, 0.7)",
    flexWrap: "nowrap"
  },
  fade2: {
    color: "rgba(255, 255, 255, 0.4)",
    flexWrap: "nowrap"
  },
  fade3: {
    color: "rgba(255, 255, 255, 0.1)",
    flexWrap: "nowrap"
  },
  fadex: {
    color: "rgba(255, 255, 255, 0.0)",
    flexWrap: "nowrap"
  }
});
