/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { NavigationContainer, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { ColorSchemeName } from 'react-native';
import LobbyScreen from '../screens/LobbyScreen';
import MainScreen from '../screens/MainScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import StatisticsScreen from '../screens/StatisticsScreen';
import { PlayScreen } from '../screens/PlayScreen';
import SettingsScreen from '../screens/SettingsScreen';
import TutorialScreen from '../screens/TutorialScreen';
import Tutorial1Screen from '../screens/Tutorial1Screen';
import { RootStackParamList } from '../types';
import LinkingConfiguration from './LinkingConfiguration';
import { TestScreen } from '../screens/TestScreen';

export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
    return (
        <NavigationContainer linking={LinkingConfiguration} theme={DarkTheme} >
            <Stack.Navigator initialRouteName="Main" screenOptions={{ headerShown: false }}>
                <Stack.Screen name="Main" component={MainScreen} options={{ title: 'Sudoku' }} />
                <Stack.Screen name="Lobby" component={LobbyScreen} options={{ title: 'Lobby' }} />
                <Stack.Screen name="Play" component={PlayScreen} options={{ title: 'Play' }} />
                <Stack.Screen name="Statistics" component={StatisticsScreen} options={{ title: 'Statistics' }} />
                <Stack.Screen name="Tutorial" component={TutorialScreen} options={{ title: 'Tutorial' }} />
                <Stack.Screen name="Tutorial1" component={Tutorial1Screen} options={{ title: 'Tutorial 1' }} />
                <Stack.Screen name="Settings" component={SettingsScreen} options={{ title: 'Settings' }} />
                <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Not Found' }} />
                <Stack.Screen name="Test" component={TestScreen} options={{ title: 'Test' }} />
            </Stack.Navigator>
        </NavigationContainer>
    );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();
