import React from 'react';
import { LayoutChangeEvent, TouchableHighlight, View } from 'react-native';
import { BorderType, Candidate, Cell } from '../models/Cell';
import { Text } from '../components/Themed';
import { GridCellStyle } from '../styles/GridCellStyle';
import { mark1, mark2, mark3, mark4, mark5 } from '../styles/StyleVariables';

export type GridCellProps = {
   cell: Cell,
   handleCellClick: (Cell: Cell) => void
}

export default function GridCell({ cell, handleCellClick }: GridCellProps): JSX.Element {
   function GetCandidateColorCss(candidate: Candidate | undefined) {
      switch (candidate?.Type) {
         case "Blocked": return GridCellStyle.neutralCandidate;
         case "Ghost": return GridCellStyle.ghostCandidate;
         case "Available":
            switch (candidate?.Color) {
               case "Color1": return GridCellStyle.candidateColor1;
               case "Color2": return GridCellStyle.candidateColor2;
               default: return GridCellStyle.neutralCandidate;
            }
      }
   }

   function collisionCandidate(candidate: Candidate | undefined) {
      if (!candidate)
         return false;

      switch (candidate.Type) {
         case "Available": return candidate.Collision;
         case "Blocked": return false;
      }
   }

   function GetCandidateCss(candidate: Candidate | undefined) {
      return [
         GetCandidateColorCss(candidate),
         GridCellStyle.candidateNumber,
         collisionCandidate(candidate) ? GridCellStyle.candidateCollision : {},
         candidate?.Type == "Blocked" ? GridCellStyle.candidateExcluded : {},
         getCandidateHighlight(candidate)
      ];
   }

   function getCandidateHighlight(candidate: Candidate | undefined) {
      switch (candidate?.Highlight) {
         case "None": return {};
         case "Color1": return GridCellStyle.highlightCandidate1;
         case "Color2": return GridCellStyle.highlightCandidate2;
         case "Color3": return GridCellStyle.highlightCandidate3;
         case "Color4": return GridCellStyle.highlightCandidate4;
         case "Remove": return GridCellStyle.highlightCandidateRemove;
      }
   }

   function getCandidateHolderCss(candidate: Candidate | undefined) {
      return [
         GridCellStyle.candidateHolder,
         candidate?.Type == "Blocked" ? [GridCellStyle.strike] : {}
      ];
   }

   function GetCandidateBox(number: number, allCandidates: Candidate[]) {
      const candidate = allCandidates.filter(v => v.Number == number).pop();
      // If this is the first cell then use it to fetch dimensions for candidates
      return <View key={number} style={getCandidateHolderCss(candidate)}>
         <Text style={GetCandidateCss(candidate)}>
            {allCandidates.some((v) => v.Number == number) ? `${number}` : " "}
         </Text>
      </View>;
   }

   function GetCandidateRow(number: number, candidates: Candidate[]) {
      return <View key={number} style={[GridCellStyle.row]}>
         {[...Array(3).keys()].map(k => GetCandidateBox((k + 1) + ((number) * 3), candidates))}
      </View>
   }

   function getCandidates(candidates: Candidate[]) {
      return <View style={GridCellStyle.candidateGrid}>
         {[...Array(3).keys()].map((number) => GetCandidateRow(number, candidates))}
      </View>
   }

   function getHolderCss() {
      return [
         GridCellStyle.box,
         cell.Index % 2 == 0 ? GridCellStyle.oddRow : {},
         cell.Column % 3 == 0 ? GridCellStyle.borderRight : {},
         (cell.Column - 1) % 3 == 0 ? GridCellStyle.borderLeft : {},
         cell.Row % 3 == 0 ? GridCellStyle.borderBottom : {},
         (cell.Row - 1) % 3 == 0 ? GridCellStyle.borderTop : {}
      ];
   }

   function getCellHighlightCss() {
      switch (cell.Highlight?.Type) {
         case "Selected": return GridCellStyle.selectedCell;
         case "Taken": return GridCellStyle.blockCell;
      }
   }

   function getContentCss() {
      switch (cell.Type) {
         case "Given": return GridCellStyle.givenCell;
         case "Value": return GridCellStyle.selectedValueCell;
         case "Candidates": return GridCellStyle.candidateCell;
      }
   }

   function getCellContent() {
      if (cell.Type == "Given")
         return cell.Given;
      if (cell.Type == "Value")
         return cell.SelectedValue;
      if (cell.Type == "Candidates" && cell.Candidates.length > 0)
         return getCandidates(cell.Candidates);
      return <Text style={GridCellStyle.emptyCell}></Text>;
   }

   function getMarkBorder(index: number) {
      const border = cell.HintMarkBorders.filter(b => b.Index == index).pop();
      if (!border)
         return {};

      return [
         {
            borderStyle: getBorderType(index),
            borderTopColor: border.Top ? getBorderColor(border.Type, index) : null,
            borderRightColor: border.Right ? getBorderColor(border.Type, index) : null,
            borderBottomColor: border.Bottom ? getBorderColor(border.Type, index) : null,
            borderLeftColor: border.Left ? getBorderColor(border.Type, index) : null,
         }
      ];
   }

   function getBorderType(index: number) {
      switch (index) {
         // case 1: return "solid";
         // case 2: return "dotted";
         // case 3: return "dashed";
         default: return "solid";
      }
   }

   function getBorderColor(type: BorderType, color: number): string {
      switch (color) {
         case null: return "rgba(0,0,0,0)";
         case 1: return mark1;
         case 2: return mark2;
         case 3: return mark3;
         case 4: return mark4;
      };

      return "rgba(0,0,0,0)";
   }

   return <TouchableHighlight key={cell.Index} onPress={() => handleCellClick(cell)} style={[getHolderCss(), getCellHighlightCss()]}>
      <View style={[GridCellStyle.border, getMarkBorder(1)]}>
         <View style={[GridCellStyle.border, getMarkBorder(2)]}>
            <View style={[GridCellStyle.border, getMarkBorder(3)]}>
               <View style={[GridCellStyle.border, getMarkBorder(4)]}>
                  <View style={[getContentCss()]}>
                     {getCellContent()}
                  </View>
               </View>
            </View>
         </View>
      </View>
   </TouchableHighlight>;
}
