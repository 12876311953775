import { Dimensions } from "react-native";

const { width, height } = Dimensions.get('window');
export const PAGE_WIDTH = Math.min(height * 0.6, width);
export const backgroundColor = "rgba(29, 31, 32, 0.8)";
export const backgroundColorAlt = "rgba(29, 31, 32, 1)";
export const selectedCellColor = "rgba(99, 255, 255, 0.4)";
export const bigFontSize = PAGE_WIDTH / 19;
export const smallFontSize = PAGE_WIDTH / 31;
export const border = "#000";
export const borderAlt = "rgba(200, 200, 200, 0.4)";
export const borderHint = "rgba(200, 100, 50, 0.7)";
export const textColor = "rgba(255, 255, 255, 0.8)";
export const textColorAlt = "rgba(255, 255, 255, 0.6)";
export const textColorGray = "rgba(255, 255, 255, 0.5)";
export const candidateColor1 = "rgba(157, 126, 0, 1)";
export const candidateColor2 = "rgba(80, 126, 255, 1)";
export const candidateColor3 = "rgba(157, 80, 255, 1)";
export const highlightCandidate1 = "rgba(0, 125, 125, 0.7)";
export const highlightCandidate2 = "rgba(125, 125, 0, 0.7)";
export const highlightCandidate3 = "rgba(125, 0, 125, 0.7)";
export const highlightCandidate4 = "rgba(125, 255, 125, 0.7)";
export const mark1 = "#35B997";
export const mark2 = "#F9DF30";
export const mark3 = "#F05561";
export const mark4 = "#0F89CA";
export const mark5 = "#91C13E";
export const highlightCandidateColorRed = "rgba(255, 100, 50, 0.7)";
export const blockColor = "rgba(200, 130, 130, 0.3)";
