import { StyleSheet } from 'react-native';
import {
   backgroundColor,
   backgroundColorAlt,
   bigFontSize, blockColor,
   border, borderAlt, candidateColor1, candidateColor2,
   highlightCandidateColorRed, smallFontSize,
   textColor, textColorAlt, textColorGray, highlightCandidate1,
   highlightCandidate2, candidateColor3, highlightCandidate3,
   highlightCandidate4,
   selectedCellColor
} from './StyleVariables';

export const GridCellStyle = StyleSheet.create({
   text: {
      fontSize: smallFontSize
   },
   box: {
      flex: 1,
      aspectRatio: 1,
      borderWidth: 1,
      borderColor: border,
      userSelect: "none",
      backgroundColor: backgroundColor,
   },
   oddRow: {
      backgroundColor: backgroundColorAlt,
   },
   borderRight: {
      borderRightColor: borderAlt,
   },
   borderLeft: {
      borderLeftColor: borderAlt,
   },
   borderBottom: {
      borderBottomColor: borderAlt,
   },
   borderTop: {
      borderTopColor: borderAlt,
   },
   emptyCell: {},
   selectedCell: {
      backgroundColor: "rgba(99, 255, 255, 0.4)",
   },
   highlightedCell: {
      backgroundColor: selectedCellColor,
   },
   markedCell: {

   },
   blockCell: {
      backgroundColor: blockColor,
   },
   selectedValueCell: {
      fontSize: bigFontSize,
      color: textColor,
      flex: 1,
      alignSelf: "center",
      justifyContent: "center",
      alignItems: "center",
      textAlign: 'center',
   },
   givenCell: {
      fontSize: bigFontSize,
      color: textColor,
      fontWeight: "bold",
      flex: 1,
      alignSelf: "center",
      justifyContent: "center",
      alignItems: "center",
      textAlign: 'center',
   },
   candidateGrid: {
      flex: 1,
      flexDirection: "column",
   },
   candidateCell: {
      flex: 1
   },
   row: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
   },
   candidateHolder: {
      height: "100%",
      flex: 1,
      textAlign: "center"
   },
   strike: {
      textDecorationLine: 'line-through',
      color: "red"
   },
   candidateNumber: {
      fontSize: smallFontSize,
   },
   candidateExcluded: {
      textDecorationLine: 'line-through',
   },
   highlightCandidate1: {
      backgroundColor: highlightCandidate1,
   },
   highlightCandidate2: {
      backgroundColor: highlightCandidate2,
   },
   highlightCandidate3: {
      backgroundColor: highlightCandidate3,
   },
   highlightCandidate4: {
      backgroundColor: highlightCandidate4,
   },
   highlightCandidateRemove: {
      backgroundColor: highlightCandidateColorRed,
   },
   candidateCollision: {
      color: "#F55",
   },
   neutralCandidate: {
      color: textColorAlt,
   },
   ghostCandidate: {
      color: textColorGray,
   },
   candidateColor1: {
      color: candidateColor1
   },
   candidateColor2: {
      color: candidateColor2,
   },
   candidateColor3: {
      color: candidateColor3,
   },
   border: {
      borderWidth: 1,
      flex: 1,
      aspectRatio: 1,
      borderColor: "rgba(0,0,0,0)"
   },
   borderDotted: {
      borderStyle: "dotted"
   }
});
