
import { StyleSheet } from 'react-native';
import { bigFontSize } from './StyleVariables';

export const PlayScreenStyle = StyleSheet.create({
    text: {
        fontSize: bigFontSize / 2,
        color: "#111111"
    },
    input: {
        color: "#111111",
        backgroundColor: "rgba(255, 255, 255, 255)",
        padding: 10,
        width: 400
    },
    container: {
        flex: 1,
        alignItems: 'center',
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    button: {
        alignItems: "center",
        backgroundColor: "#DDDDDD",
        padding: 10,
        margin: 10,
        marginTop: 20
    },
    bottomButtons: {
        flexDirection: "row"
    }
});
