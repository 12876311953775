import { StyleSheet } from 'react-native';
import { bigFontSize, PAGE_WIDTH } from './StyleVariables';

export const GridStyle = StyleSheet.create({
    grid: {
        marginTop: 60,
        width: PAGE_WIDTH,
        fontFamily: ["monospace"],
    },
    svgView: {
        width: PAGE_WIDTH,
        height: PAGE_WIDTH,
        marginTop: 60,
        position: "absolute",
        backgroundColor: "rgba(0,0,0,0)"
    },
    svg: {
        marginTop: 60,
        position: "absolute",
        backgroundColor: "rgba(0,0,0,0)",

        width: PAGE_WIDTH,
        height: PAGE_WIDTH,
        stroke: "rgba(125, 125, 0, 0.7)",
        strokeWidth: 2
    },
    hints: {
        width: PAGE_WIDTH,
    },
    hintLink: {
        color: "blue",
        textDecorationLine: "underline"
    },
    hint: {
        marginTop: 5,
    },
    hintDetails: {
        color: "FFFFFF"
    },
    hintResult: {
        display: 'flex',
        flexDirection: 'row'
    },
    hintResultText: {
    },
    hintAction: {
    },
    button: {
        alignItems: "center",
        backgroundColor: "#DDDDDD",
        padding: 2,
        marginLeft: 5
    },
    text: {
        color: "#111111",
        fontSize: bigFontSize / 3
    },
});
