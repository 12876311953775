import { Cell } from "../models/Cell";

export type ApiCandidate = {
    Number: number,
    Blocked: boolean
}

export type PuzzleRequestCellData = {
    Column: number,
    Row: number,
    SelectedValue: number | null,
    GivenValue: number | null,
    Candidates: ApiCandidate[]
}

export function getApiPuzzleRequestModel(cell: Cell): PuzzleRequestCellData {
    return {
        Column: cell.Column,
        Row: cell.Row,
        SelectedValue: cell.Type == "Value" ? cell.SelectedValue : null,
        GivenValue: cell.Type == "Given" ? cell.Given : null,
        Candidates: cell.Type == "Candidates"
            ? cell.Candidates.map(c => {
                return { Number: c.Number, Blocked: c.Type == "Blocked" }
            })
            : [],
    };
}