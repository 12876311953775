import { Candidate, Cell } from "../models/Cell";
import { indexColumn, indexBox, indexRow } from "./Grid";

export function CreateCell(index: number, given: number | null, candidates: number[] | null): Cell {

    const column = indexColumn[index],
        row = indexRow[index],
        box = indexBox[index];

    var base = {
        Index: index,
        Column: column,
        Row: row,
        Box: box,
        Highlight: null,
        HintMarkBorders: []
    }

    return given
        ? {
            ...base,
            Type: "Given",
            Given: given,
        }
        : {
            ...base,
            Type: "Candidates",
            Candidates: getCandidates(candidates)
        };
}

export default function getCandidates(candidates: number[] | null): Candidate[] {
    return candidates?.map(getCandidate) ?? [];
}

function getCandidate(candidate: number): Candidate {
    return {
        Type: "Available",
        Number: candidate,
        Color: "Neutral",
        Collision: false,
        Highlight: "None",
        HintArrows: []
    }
}

export function getCellsAsRows(puzzle: Cell[]) {
    let rows = [];
    for (let i = 0; i < 80; i += 9) {
        rows.push(puzzle.slice(i, i + 9));
    }
    return rows;
}
